import { Link } from 'react-router-dom'
import Logo from '../common/Logo'
import styles from './About.module.css'

const DISCORD_INVITE = process.env.REACT_APP_DISCORD_INVITE

function About() {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '512px',
                margin: 'auto',
                alignItems: 'center',
                gap: '32px',
            }}
        >
            <Logo className={styles.logo} />
            <div className={styles.wrapper}>
                Welcome to gToons Remastered! An attempt to re-create{' '}
                <a href="https://en.wikipedia.org/wiki/Cartoon_Orbit#gToons">gToons</a>, Cartoon Orbit's digital
                collectible card game that was online from October 14, 2002 to October 16, 2006. I started this project
                during the COVID lockdowns when I had lots of free time. In it's current state, you're able to build
                decks with all the known cards and play against your friends or the computer. Join our{' '}
                <a href={DISCORD_INVITE}>Discord server</a>!
            </div>
            <Link className={styles.button} to="/home">
                Home
            </Link>
        </div>
    )
}

export default About
