import { configureStore } from '@reduxjs/toolkit'
import watch from 'redux-watch'
import gameReducer from './game'
import { initialize } from './initialize'
import lobbyReducer from './lobby'
import preferencesReducer from './preferences'
import userReducer from './user'

const store = configureStore({
    reducer: {
        game: gameReducer,
        user: userReducer,
        preferences: preferencesReducer,
        lobby: lobbyReducer,
    },
})

export const watchUser = watch(store.getState, 'user')
export const watchGame = watch(store.getState, 'game')
export const watchPreferences = watch(store.getState, 'preferences')

export default store

initialize(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
