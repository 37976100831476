const GTOONS_WS_URL = process.env.REACT_APP_GTOONS_WS_URL

export interface PersonDTO {
    id: string
    name: string
}

export interface ChallengeDTO {
    id: string
    challenger: PersonDTO
    challengee: PersonDTO
}

export interface MessageDTO {
    id: string
    messager: PersonDTO
    content: string
}

export type Lobby = {
    next: () => Promise<LobbyEvent>
    send: (e: PersonEvent) => void
    done: () => void
}

export function connectToLobby(id: string, username: string): Lobby {
    const ws = new WebSocket(`${GTOONS_WS_URL}/api/v1/lobby?id=${id}&name=${username}`)

    const events: LobbyEvent[] = []
    var waiting: ((e: LobbyEvent) => void) | undefined

    newEvent({ type: 'status', status: 'connecting' })

    ws.onopen = () => newEvent({ type: 'status', status: 'connected' })
    ws.onclose = () => newEvent({ type: 'status', status: 'disconnected' })
    ws.onerror = () => newEvent({ type: 'status', status: 'disconnected' })
    ws.onmessage = (m) => newEvent(JSON.parse(m.data) as LobbyEvent)

    function newEvent(e: LobbyEvent) {
        if (waiting) {
            waiting(e)
        } else {
            events.push(e)
        }
    }

    return {
        next: () => {
            if (events.length > 0) return Promise.resolve(events.shift()!)
            return new Promise<LobbyEvent>((resolve) => {
                waiting = resolve
            }).then((e) => {
                waiting = undefined
                return e
            })
        },
        send: (e: PersonEvent) => {
            ws.send(JSON.stringify(e))
        },
        done: () => {
            ws.close()
        },
    }
}

// PersonEvent
export type PersonEvent =
    | { type: 'accept' | 'decline'; challenge: string }
    | { type: 'message'; content: string }
    | { type: 'challenge'; person: string }

// LobbyEvent
export type LobbyEvent =
    | LobbyEventPeople
    | LobbyEventChallenges
    | LobbyEventMessage
    | LobbyEventAccepted
    | LobbyEventStatus

export type LobbyEventPeople = { type: 'people'; people: PersonDTO[] }
export type LobbyEventChallenges = { type: 'challenges'; challenges: ChallengeDTO[] }
export type LobbyEventMessage = { type: 'message'; message: MessageDTO }
export type LobbyEventAccepted = { type: 'accepted'; id: string }
export type LobbyEventStatus = { type: 'status'; status: 'connected' | 'connecting' | 'disconnected' }
