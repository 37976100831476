import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { RootState } from './store'

export type LobbyState = {
    people: Person[]
    challenges: Challenge[]
    messages: Message[]

    acceptedChallenge: Challenge | undefined

    status: 'connecting' | 'connected' | 'disconnected'
}

export type Person = {
    id: string
    name: string
    label: 'me' | 'pending-challenge' | 'can-challenge'
}

export type Challenge = {
    id: string
    opponent: { id: string; name: string }
    type: 'challenged' | 'accepted'
}

export type Message = {
    id: string
    messenger: string
    content: string
    date: string
}

export const initial: () => LobbyState = () => ({
    people: [],
    challenges: [],
    messages: [],
    acceptedChallenge: undefined,

    status: 'disconnected',
})

const slice = createSlice({
    name: 'lobby',
    initialState: initial,
    reducers: {
        reset() {
            return initial()
        },

        statusChange(state, action: PayloadAction<LobbyState['status']>) {
            state.status = action.payload
        },

        challengeAccepted(state, action: PayloadAction<string>) {
            state.acceptedChallenge = state.challenges.find((c) => c.id === action.payload)
        },

        newMessage(state, action: PayloadAction<Message>) {
            if (state.messages.find((m) => m.id === action.payload.id)) return

            state.messages.push(action.payload)

            if (state.messages.length > 50) {
                state.messages.shift()
            }
        },

        challengesUpdated(state, action: PayloadAction<Challenge[]>) {
            state.challenges = action.payload
        },

        peopleUpdated(state, action: PayloadAction<Person[]>) {
            state.people = action.payload

            for (const person of state.people) {
                if (state.challenges.find((c) => c.opponent.id === person.id)) {
                    person.label = 'pending-challenge'
                }
            }
        },
    },
})

export function useLobbySelector<T>(f: (state: LobbyState) => T): T {
    return useSelector((state: RootState) => f(state.lobby))
}

export function useLobbyState(): LobbyState {
    return useSelector((state: RootState) => state.lobby)
}

export default slice.reducer

export const actions = slice.actions
