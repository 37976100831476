import { FunctionComponent, useRef } from 'react'
import { PlayType } from '../apis/gtoons'
import { Alert } from './components/Alert'
import Cursor from './components/Cursor'
import Debug from './components/Debug'
import DeckSelect from './components/DeckSelect'
import GameBoard from './components/GameBoard'
import GameDrawerLeft from './components/GameDrawerLeft'
import GameDrawerRight from './components/GameDrawerRight'
import GameSounds from './components/GameSounds'
import GameToolsContext from './components/GameTools'
import Loading from './components/Loading'
import Results from './components/Results'
import SVGDefs from './components/SVGDefs'
import styles from './Game.module.css'
import useGameAudio from './useGameAudio'
import useGameEngine from './useGameEngine'

type GameProps = {
    type: PlayType
}

const Game: FunctionComponent<GameProps> = ({ type }) => {
    const sendUIEvent = useGameEngine(type)
    const playSound = useGameAudio()
    const game = useRef<HTMLDivElement>(null)

    return (
        <GameToolsContext.Provider value={{ playSound, sendUIEvent }}>
            <div ref={game} className={styles['game']}>
                <svg className={styles['game-svg']} version="1.1" baseProfile="full" viewBox="0 0 1024 768">
                    <SVGDefs />
                    <Cursor>
                        <GameBoard />
                        <GameDrawerLeft />
                        <GameDrawerRight />
                    </Cursor>
                    <Results />
                    <DeckSelect />
                    <Loading />
                    <Alert />
                    <Debug />
                    <GameSounds x={40} y={10} />
                </svg>
            </div>
        </GameToolsContext.Provider>
    )
}

export default Game
