import { FunctionComponent } from 'react'
import { selectHandPositions, useGameSelector } from '../../state/game'
import { HAND_SIZE } from '../constants'
import styles from '../Game.module.css'
import DiscardDrawer from './DiscardDrawer'
import { AnimatedPosition } from './Position'
import TV from './TV'

const GameDrawerRight: FunctionComponent = () => {
    const handPositions = useGameSelector(selectHandPositions)
    const tv = useGameSelector((s) => s.tv)
    const phase = useGameSelector((s) => s.phase)

    if (phase === 'CONNECTING' || phase === 'LOADING' || phase === 'DECK_SELECT') return <></>

    return (
        <g className={styles['slide-left']}>
            {/* Background */}
            <DiscardDrawer state="open" x={842} y={564} />
            <svg width={170} height={632} x={826} y={52}>
                <path
                    fill="url('#drawer-background')"
                    d="M 161.0267 2.6365 L 5.0267 48.4714 C 2.0467 49.347 0 52.0815 0 55.1875 L 0 576.9869 C 0 580.0954 2.05 582.8316 5.0333 583.7049 L 161.0333 629.3749 C 164.7435 630.4611 168.6318 628.3339 169.718 624.6236 C 169.9051 623.9848 170 623.3226 170 622.6569 L 170 9.3526 C 170 5.4866 166.866 2.3526 163 2.3526 C 162.3321 2.3526 161.6676 2.4482 161.0267 2.6365 Z"
                />
                <svg width={156} height={286} x={8} y={16}>
                    <path
                        fill="url('#panel-background')"
                        d="M147.1377 2.4459 5.1377 41.6358C2.1024 42.4735 0 45.2347 0 48.3835L0 279C0 282.866 3.134 286 7 286L149 286C152.866 286 156 282.866 156 279L156 9.1936C156 5.3276 152.866 2.1936 149 2.1936C148.3707 2.1936 147.7443 2.2784 147.1377 2.4459Z"
                    />
                </svg>
                <rect fill="#83a2bb" x={8} y={338} width={154} height={240} rx={7} />
            </svg>

            <TV x={828 + 8} y={52 + 16} card={tv} />

            {/* Hand */}
            {handPositions.map(({ position, status }) => (
                <AnimatedPosition key={position} position={position} status={status} size={HAND_SIZE} />
            ))}
        </g>
    )
}

export default GameDrawerRight
