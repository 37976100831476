import { useEffect, useState } from 'react'

// eslint-disable-next-line
export type FetchRequest<T> = {
    input: RequestInfo
    init?: RequestInit
    overrideResponse?: T
}

export function useFetch<T>({ input, init, overrideResponse }: FetchRequest<T>): [boolean, T, Error | null] {
    const [data, setData] = useState<any>(null)
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState<Error | null>(null)

    useEffect(() => {
        const doFetch = async () => {
            try {
                const response = await fetch(input, init)
                const json = (await response.json()) as T

                setIsLoading(false)
                setData(json)
                setError(null)
            } catch (error) {
                setIsLoading(false)
                setError(error as Error)
                setData(null)
            }
        }

        if (overrideResponse) {
            setIsLoading(false)
            setData(overrideResponse)
            setError(null)
        } else {
            doFetch()
        }
    }, [input, init, overrideResponse])

    return [isLoading, data, error]
}
