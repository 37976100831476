import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { deckUpdated, useUserSelector } from '../state/user'

export const DEFAULT_DECKS = [
    { name: 'A', cards: [] },
    { name: 'B', cards: [] },
    { name: 'C', cards: [] },
    { name: 'D', cards: [] },
]

export function validDeck(deck: Deck) {
    return Array.isArray(deck.cards) && deck.cards.length <= 12
}

export function isComplete(deck: Deck) {
    return validDeck(deck) && deck.cards.length === 12
}

export type Deck = {
    cards: string[]
    name: string
}

export const useDeck = (id: string) => {
    const dispatch = useDispatch()
    const decks = useUserSelector((u) => u.decks)
    const [deck, setDeck] = useState(decks.find((d) => d.name === id)!)

    const add = (id: string) => {
        const newDeck = { name: deck.name, cards: [...deck.cards] }

        newDeck.cards.push(id)
        if (validDeck(newDeck)) {
            setDeck(newDeck)
        }
    }

    const remove = (id: string) => {
        const newDeck = {
            name: deck.name,
            cards: deck.cards.filter((c) => c !== id),
        }

        if (validDeck(newDeck)) {
            setDeck(newDeck)
        }
    }

    const set = (ids: string[]) => {
        const newDeck = {
            name: deck.name,
            cards: ids,
        }

        if (validDeck(newDeck)) {
            setDeck(newDeck)
        }
    }

    const save = () => {
        dispatch(deckUpdated(deck))
    }

    return { deck, add, remove, set, save }
}

export const deserializeDeck = (name: string, text: string): Deck => {
    const lines = Array.from(new Set(text.split('\n').filter((line) => line !== '')))
    return {
        name,
        cards: lines,
    }
}

export const serializeDeck = (deck: Deck): string => {
    return deck.cards.join('\n')
}
