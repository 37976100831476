import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { Challenge, useLobbyState } from '../../state/lobby'
import Challenges from '../components/Challenges'
import Chat from '../components/Chat'
import LobbyPanel from '../components/LobbyPanel'
import People from '../components/People'
import styles from '../Lobby.module.css'
import useLobby from '../useLobby'

const Lobby: FunctionComponent = () => {
    const { sendMessage, issueChallenge, acknowledgeChallenge } = useLobby()
    const { people, challenges, messages, acceptedChallenge } = useLobbyState()

    return (
        <div className={styles.main}>
            <div className={styles.spacer} />
            <LobbyPanel title="Lobby" className={styles['panel-lobby']}>
                <People challenge={(id) => issueChallenge(id)} people={people} />
            </LobbyPanel>

            <LobbyPanel title="Challenges" className={styles['panel-challenges']}>
                <Challenges challenges={challenges} acknowledge={acknowledgeChallenge} />
            </LobbyPanel>

            <LobbyPanel title="Chat" className={styles['panel-chat']}>
                <Chat messages={messages} send={(m) => sendMessage(m)} />
            </LobbyPanel>

            <Link className={styles.home} to="/home">
                Home
            </Link>
            <div className={styles.spacer} />

            {acceptedChallenge && <AcceptedChallenge challenge={acceptedChallenge} />}
        </div>
    )
}

const AcceptedChallenge: FunctionComponent<{ challenge: Challenge }> = ({ challenge }) => {
    return (
        <div className={styles['accepted-challenge']}>
            <div className={styles['accepted-challenge-content']}>
                <h1>Game is Ready!</h1>
                <Link className={styles.play} to={`/play/${challenge.id}`}>
                    Play!
                </Link>
            </div>
        </div>
    )
}

export default Lobby
