import { FunctionComponent, useCallback, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { gameActions, useGameSelector } from '../../state/game'
import { CARD_SIZE, CURSOR_SIZE, DISCARD_SIZE, GAME_HEIGHT, GAME_WIDTH, HAND_SIZE } from '../constants'
import { coordinateToBoardPosition, coordinateToDiscardPosition, coordinateToHandPosition } from '../coordinates'
import { UIPosition } from '../positions'

type CursorEvent = MouseEvent | TouchEvent

const Cursor: FunctionComponent = ({ children }) => {
    const dispatch = useDispatch()

    const cursorContainer = useRef<SVGSVGElement>(null)
    const svg = useRef<SVGSVGElement>(null)
    const point = useRef<SVGPoint | null>(null)

    const position = useRef<UIPosition | null>(null)
    const holding = useRef<boolean>(false)
    const showingCursor = useRef<boolean>(false)
    const showCursor = useGameSelector((s) => !!s.held)
    const positions = useGameSelector((s) => s.cardPositions)
    const tv = useGameSelector((s) => s.tv)

    useEffect(() => {
        if (svg.current) {
            point.current = svg.current.createSVGPoint()
        }
    }, [point])

    useEffect(() => {
        if (showCursor && !showingCursor.current) {
            showingCursor.current = true
            cursorContainer.current?.appendChild(cursor)
        } else if (!showCursor && showingCursor.current) {
            showingCursor.current = false
            cursorContainer.current?.removeChild(cursor)
        }
    }, [showingCursor, cursorContainer, showCursor])

    const event = useCallback(
        function (this: SVGElement, event: CursorEvent) {
            if (!point.current) return // dependencies not set yet

            // transform cursor point into svg point coordinates
            if (window.TouchEvent && event instanceof window.TouchEvent) {
                point.current.x = event.changedTouches[0].clientX
                point.current.y = event.changedTouches[0].clientY
            } else if (event instanceof window.MouseEvent) {
                point.current.x = event.clientX
                point.current.y = event.clientY
            }

            const svgPoint = point.current.matrixTransform(svg.current?.getScreenCTM()?.inverse())

            cursor.setAttribute('x', `${svgPoint.x - CURSOR_SIZE / 2}`)
            cursor.setAttribute('y', `${svgPoint.y - CURSOR_SIZE / 2}`)

            const boardPosition = coordinateToBoardPosition(svgPoint, CARD_SIZE)
            const handPosition = coordinateToHandPosition(svgPoint, HAND_SIZE)
            const discardPosition = coordinateToDiscardPosition(svgPoint, DISCARD_SIZE)

            if (showingCursor.current) {
                event.preventDefault()
            }

            if (event.type === 'mousedown' || event.type === 'touchstart') {
                position.current = boardPosition || handPosition || discardPosition || null
            } else if (event.type === 'mouseup' || event.type === 'touchend') {
                var dropPosition = boardPosition || handPosition || discardPosition
                dispatch(gameActions.drop(dropPosition))
                position.current = null
                holding.current = false
            } else if (event.type === 'mousemove' || event.type === 'touchmove') {
                if (position.current && !holding.current) {
                    dispatch(gameActions.hold(position.current))
                    holding.current = true
                }
            } else if (event.type === 'mouseleave' || event.type === 'touchcancel') {
                position.current = null
                holding.current = false
                dispatch(gameActions.drop())
            }

            if (event.type === 'touchend' || event.type === 'mousemove') {
                const position = boardPosition || handPosition || discardPosition

                if (position) {
                    const status = positions[position]
                    if (status.type === 'card' && tv?.id !== status.card.id) {
                        dispatch(gameActions.setGameTV(status.card))
                    }
                }
            }
        },
        [dispatch, positions, tv]
    )

    useEffect(() => {
        const copy = svg.current
        copy?.addEventListener('mousemove', event, { passive: false })
        copy?.addEventListener('mousedown', event, { passive: false })
        copy?.addEventListener('mouseup', event, { passive: false })
        copy?.addEventListener('mouseleave', event, { passive: false })
        copy?.addEventListener('touchstart', event, { passive: false })
        copy?.addEventListener('touchend', event, { passive: false })
        copy?.addEventListener('touchcancel', event, { passive: false })
        copy?.addEventListener('touchmove', event, { passive: false })

        return () => {
            copy?.removeEventListener('mousemove', event)
            copy?.removeEventListener('mousedown', event)
            copy?.removeEventListener('mouseup', event)
            copy?.removeEventListener('mouseleave', event)
            copy?.removeEventListener('touchstart', event)
            copy?.removeEventListener('touchend', event)
            copy?.removeEventListener('touchcancel', event)
            copy?.removeEventListener('touchmove', event)
        }
    }, [event])

    return (
        <svg width={GAME_WIDTH} height={GAME_HEIGHT} x={0} y={0} ref={svg}>
            <rect x={0} y={0} width={GAME_WIDTH} height={GAME_HEIGHT} fillOpacity={0} />
            {children}
            <svg id="cursor-container" ref={cursorContainer} x={0} y={0} />
        </svg>
    )
}

const cursor = (() => {
    const container = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
    container.setAttribute('width', `${CURSOR_SIZE}`)
    container.setAttribute('height', `${CURSOR_SIZE}`)

    const path = document.createElementNS('http://www.w3.org/2000/svg', 'path')
    path.setAttribute(
        'd',
        'M40,0 C62.09139,0 80,17.90861 80,40 C80,52.8905756 73.9023792,64.3569755 64.4341457,71.6721917 C67.9642208,68.9041238 70.2325581,64.6006821 70.2325581,59.7674419 C70.2325581,51.4189515 63.4647695,44.6511628 55.1162791,44.6511628 C46.7677887,44.6511628 40,51.4189515 40,59.7674419 C40,68.1159323 46.7677887,74.8837209 55.1162791,74.8837209 C58.5381395,74.8837209 61.6944424,73.746734 64.2280083,71.8299397 C57.5038428,76.9563018 49.1073683,80 40,80 C17.90861,80 0,62.09139 0,40 C0,17.90861 17.90861,0 40,0 Z M55.1162791,47.872093 C61.6858988,47.872093 67.0116279,53.1978221 67.0116279,59.7674419 C67.0116279,66.3370616 61.6858988,71.6627907 55.1162791,71.6627907 C48.5466593,71.6627907 43.2209302,66.3370616 43.2209302,59.7674419 C43.2209302,53.1978221 48.5466593,47.872093 55.1162791,47.872093 Z'
    )
    path.setAttribute('fill', '#E3EEF4')
    path.setAttribute('fill-opacity', '0.8')

    container.appendChild(path)
    return container
})()

export default Cursor
