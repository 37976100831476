import Ajv, { AnySchemaObject, JSONSchemaType } from 'ajv'
import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { debug } from '../apis/gtoons'
import { gameActions, useGameSelector } from '../state/game'
import useGameEngine, { UIEventSender } from './useGameEngine'

const ajv = new Ajv({ coerceTypes: true })

export type Players<T> = { player: T; opponent: T }
export type DebugGameData = {
    decks: Players<string[]>
    battlezone: Players<string[][]>
    silver: Players<string>
    swap: Players<string | undefined>
    discard: Players<string[]>
    names: Players<string>
}

function players<T>(type: JSONSchemaType<T>): JSONSchemaType<Players<T>> {
    const schema: AnySchemaObject = {
        type: 'object',
        properties: { player: type, opponent: type },
        required: ['player', 'opponent'],
        additionalProperties: false,
    }

    return schema as JSONSchemaType<Players<T>>
}

const schema: JSONSchemaType<DebugGameData> = {
    type: 'object',
    properties: {
        decks: players({ type: 'array', items: { type: 'string' } }),
        battlezone: players({ type: 'array', items: { type: 'array', items: { type: 'string' } } }),
        silver: players({ type: 'string' }),
        swap: {
            type: 'object',
            properties: {
                player: { type: 'string', nullable: true },
                opponent: { type: 'string', nullable: true },
            },
        },
        discard: players({ type: 'array', items: { type: 'string' } }),
        names: players({ type: 'string' }),
    },
    required: ['battlezone', 'decks', 'discard', 'silver', 'swap', 'names'],
}

export const validate = ajv.compile(schema)

export const useDebugEngine = (data: DebugGameData): UIEventSender => {
    const phase = useGameSelector((s) => s.phase)
    const dispatch = useDispatch()
    const buildDebugClient = useCallback(() => {
        const ws = debug(data.names.player, data.names.opponent)
        ws.addEventListener('open', () => {
            ws.send(JSON.stringify(data))
        })
        return ws
    }, [data])

    const sendUIEvent = useGameEngine(buildDebugClient)

    useEffect(() => {
        console.log(phase)
        if (phase === 'DECK_SELECT') {
            sendUIEvent({ type: 'deck', deck: { cards: data.decks.player, name: 'A' } })
        }
        if (phase === 'PLAY_1') {
            setTimeout(() => dispatch(gameActions.autoPlay(data.battlezone.player[0])), 100)
        }
        if (phase === 'PLAY_2') {
            setTimeout(() => dispatch(gameActions.autoPlay(data.battlezone.player[1])), 100)
        }
        if (phase === 'DISCARD') {
            setTimeout(() => dispatch(gameActions.autoPlay(data.discard.player)), 100)
        }
        if (phase === 'SILVER') {
            sendUIEvent({ type: 'color', color: data.silver as any })
        }
        if (phase === 'SWAP') {
            sendUIEvent({ type: data.swap.player ? 'yes' : 'no' })
        }
        if (phase === 'SWAP_YES') {
            setTimeout(() => dispatch(gameActions.autoPlay([data.swap.player!])))
        }
    }, [phase, dispatch, sendUIEvent, data])

    return sendUIEvent
}
