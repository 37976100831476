import { CSSProperties, FunctionComponent, SVGAttributes } from 'react'
import { Card as CardDTO } from '../../apis/gtoons'
import Card from '../../common/Card'
import CardBack from '../../common/CardBack'
import { CardType } from '../../common/CardType'
import { FONT } from '../styling'

type TVProps = {
    card?: CardDTO | 'card-back'
} & SVGAttributes<SVGElement>

const TV: FunctionComponent<TVProps> = ({ card, x, y }) => {
    const textOptions: CSSProperties = {
        fontSize: '12px',
        fontFamily: FONT,
        fontStyle: 'italic',
        fontWeight: 'bold',
        color: '#A2F5F5',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
    }

    var display = <></>

    if (!card) return <></>
    else if (card === 'card-back') display = <CardBack x={21} y={40} width={114} height={114} />
    else
        display = (
            <>
                <Card card={card} width={114} height={114} x={21} y={40} />
                <foreignObject x="16" y="154" width="124" height="34">
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '32px',
                            gap: '4px',
                        }}
                    >
                        {card.types.map((t) => (
                            <CardType key={t} type={t} size="16px" />
                        ))}
                    </div>
                </foreignObject>

                <foreignObject x="16" y="188" width="124" height="42">
                    <span style={{ ...textOptions, fontSize: '14px' }}>{card.name}</span>
                </foreignObject>

                <foreignObject x="16" y="230" width="124" height="60">
                    <span style={textOptions}>{card.description}</span>
                </foreignObject>
            </>
        )

    return (
        <svg width={156} height={286} x={x} y={y}>
            {display}
        </svg>
    )
}

export default TV
