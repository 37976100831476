import { FunctionComponent, SVGAttributes, useContext, useEffect, useState } from 'react'
import { BsFillVolumeMuteFill, BsFillVolumeUpFill } from 'react-icons/bs'
import { useDispatch } from 'react-redux'
import { preferencesActions, usePreferencesSelector } from '../../state/preferences'
import GameToolsContext from './GameTools'

const GameSounds: FunctionComponent<SVGAttributes<SVGElement>> = (props) => {
    const dispatch = useDispatch()
    const { playSound } = useContext(GameToolsContext)

    const [hovered, setHovered] = useState<boolean>(false)
    const sounds = usePreferencesSelector((u) => u.sounds)

    // sound button toggle
    useEffect(() => {
        playSound('fwoop')
        // eslint-disable-next-line
    }, [sounds])

    const click = async () => {
        dispatch(preferencesActions.toggleSounds())
    }

    let Icon = sounds ? BsFillVolumeUpFill : BsFillVolumeMuteFill

    return (
        <svg
            {...props}
            onMouseOver={() => setHovered(true)}
            onMouseOut={() => setHovered(false)}
            onClick={() => click()}
            style={{ cursor: 'pointer' }}
            width={'1.5em'}
            height={'1.5em'}
        >
            <rect x={0} y={0} width={'100%'} height={'100%'} opacity={'0%'} />
            <Icon opacity={hovered ? '90%' : '30%'} color="white" width={'100%'} size={'100%'} />
        </svg>
    )
}

export default GameSounds
