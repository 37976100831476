import { Store } from 'redux'
import { gameActions } from './game'
import { watchPreferences, watchUser } from './store'

export function initialize(store: Store) {
    store.subscribe(
        watchUser((newValue, oldValue, path) => {
            console.log(`USER - [${path}] changed from ${JSON.stringify(oldValue)} to ${JSON.stringify(newValue)}`)
            window.localStorage.setItem('userInfo', JSON.stringify(newValue))
        })
    )

    store.subscribe(
        watchPreferences((newValue, oldValue, path) => {
            console.log(
                `PREFERENCES - [${path}] changed from ${JSON.stringify(oldValue)} to ${JSON.stringify(newValue)}`
            )
            window.localStorage.setItem('preferences', JSON.stringify(newValue))
        })
    )

    store.dispatch(gameActions.resetGameState())
}
