import { FunctionComponent } from 'react'
import { BsFillCheckSquareFill, BsFillXCircleFill } from 'react-icons/bs'
import { Challenge } from '../../state/lobby'
import styles from '../Lobby.module.css'
import Username from './Username'

type ChallengesProps = {
    challenges: Challenge[]
    acknowledge: (id: string, response: 'accept' | 'decline') => void
}
const Challenges: FunctionComponent<ChallengesProps> = ({ challenges, acknowledge }) => {
    const status = (challenge: Challenge) => {
        if (challenge.type === 'challenged') {
            return (
                <div>
                    <BsFillCheckSquareFill
                        onClick={() => acknowledge(challenge.id, 'accept')}
                        style={{ color: 'green', padding: '4px', cursor: 'pointer' }}
                    />
                    <BsFillXCircleFill
                        onClick={() => acknowledge(challenge.id, 'decline')}
                        style={{ color: 'red', padding: '4px', cursor: 'pointer' }}
                    />
                </div>
            )
        } else if (challenge.type === 'accepted') {
            return <div>Waiting...</div>
        }
    }

    return (
        <div className={styles.challenges}>
            {challenges.map((c) => (
                <div key={c.id} className={styles.challenge}>
                    <Username username={c.opponent.name} />
                    {status(c)}
                </div>
            ))}
        </div>
    )
}

export default Challenges
