import { FunctionComponent } from 'react'
import { RiSwordFill } from 'react-icons/ri'
import { Person } from '../../state/lobby'
import styles from '../Lobby.module.css'
import Username from './Username'

type PeopleProps = { people: Person[]; challenge: (id: string) => void }
const People: FunctionComponent<PeopleProps> = ({ people, challenge }) => {
    return (
        <div className={styles.people}>
            {people.map((p) => (
                <div key={p.id} className={styles.person}>
                    <Username username={p.name} />
                    {p.label === 'pending-challenge' ? (
                        <div>Waiting...</div>
                    ) : p.label === 'can-challenge' ? (
                        <div className={styles['person-challenge']} onClick={() => challenge(p.id)}>
                            <RiSwordFill style={{ marginRight: '4px' }} />
                            Challenge
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            ))}
        </div>
    )
}

export default People
