import { useSpring } from '@react-spring/core'
import { FluidValue } from '@react-spring/shared'
import { animated } from '@react-spring/web'
import { FunctionComponent, memo, SVGAttributes, useContext, useEffect } from 'react'
import Card from '../../common/Card'
import CardBack from '../../common/CardBack'
import usePrevious from '../../common/usePrevious'
import { useGameSelector } from '../../state/game'
import { positionToCoordinates } from '../coordinates'
import { Position as PositionState, PositionStatus } from '../positions'
import GameToolsContext from './GameTools'

type PositionProps = {
    status: PositionStatus
    position: PositionState
    size: number
}

const AnimatedPosition: FunctionComponent<PositionProps> = memo((props) => {
    const previous = usePrevious(props.status)?.type
    const current = props.status.type
    const phase = useGameSelector((s) => s.phase)
    const { playSound } = useContext(GameToolsContext)

    const { x } = positionToCoordinates(props.position)
    const { x: r } = useSpring({ from: { x: -180 }, x: 0, reset: true })

    let transition: 'flip' | 'lift' | 'place' | 'cpu-place' | 'open' | undefined

    // transitions
    if (previous === 'card' && current === 'card-back') transition = 'flip'
    else if (phase.startsWith('SCORING') && previous === 'card-back' && current === 'card') transition = 'flip'
    else if (previous === 'card' && current === 'open') transition = 'lift'
    else if (phase.startsWith('PLAY') && previous === 'open' && current === 'card') transition = 'place'
    else if (previous === 'open' && current === 'card') transition = 'cpu-place'
    else if (previous === 'closed' && current === 'card-back') transition = 'cpu-place'
    else if (previous === 'closed' && current === 'open') transition = 'open'

    // sounds
    useEffect(() => {
        if (transition === 'flip') playSound('swish')
        if (transition === 'lift') playSound('knock-2')
        if (transition === 'place') playSound('knock')
        if (transition === 'cpu-place') playSound('thud')
        // if (transition === 'open') playSound('woo-up')

        // eslint-disable-next-line
    }, [transition, playSound])

    // build transform animation from animation type
    let transform: FluidValue | undefined = undefined
    if (transition === 'flip') {
        const t = (x: number, p: number, r: number) =>
            `translateX(${x}px) perspective(${p}px) rotateY(${r}deg) translateX(${-x}px)`
        transform = r.to((n) => t(x + props.size / 2, 1000, n))
    }

    return (
        <animated.g style={{ transform }}>
            <Position {...props} />
        </animated.g>
    )
})

const Position: FunctionComponent<PositionProps> = ({ status, position, size }) => {
    const coords = positionToCoordinates(position)

    return (
        <>
            {status.type === 'card' && (
                <Card
                    card={status.card}
                    modifications={status.modifications}
                    disabled={status.disabled}
                    width={size}
                    height={size}
                    x={coords.x}
                    y={coords.y}
                />
            )}

            {status.type === 'closed' && (
                <svg width={size} height={size} x={coords.x} y={coords.y}>
                    <circle
                        cx="50%"
                        cy="50%"
                        r={size / 2 - 4}
                        stroke="#B2D2E4"
                        strokeWidth={(4 * size) / 114}
                        fillOpacity={0}
                    />
                </svg>
            )}

            {status.type === 'open' && <EmptySlot id={position} x={coords.x} y={coords.y} size={size} />}

            {status.type === 'card-back' && <CardBack x={coords.x} y={coords.y} width={size} height={size} />}

            <rect className={'touch-target'} width={size} height={size} x={coords.x} y={coords.y} opacity={0} />
        </>
    )
}

type EmptySlotProps = {
    size: number
} & SVGAttributes<SVGElement>

const EmptySlot: FunctionComponent<EmptySlotProps> = ({ x, y, size, id }) => {
    return (
        <>
            <svg x={x} y={y} width={size} height={size}>
                <defs>
                    <circle
                        id={`${id}-circle`}
                        r={size / 2 - Math.max((4 * size) / 114, 2)}
                        cx={size / 2}
                        cy={size / 2}
                    />
                    <clipPath id={`${id}-circleClip`}>
                        <use xlinkHref={`#${id}-circle`} />
                    </clipPath>
                    <filter id={`${id}-inset-shadow`}>
                        {/* Shadow offset */}
                        <feOffset dx="0" dy="0" />

                        {/* Shadow blur */}
                        <feGaussianBlur stdDeviation={size * 0.1153846154} result="offset-blur" />

                        {/* Invert drop shadow to make an inset shadow */}
                        <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse" />

                        {/* Cut color inside shadow */}
                        <feFlood floodColor="black" floodOpacity=".95" result="color" />
                        <feComposite operator="in" in="color" in2="inverse" result="shadow" />

                        {/* Placing shadow over element */}
                        <feComposite operator="over" in="shadow" in2="SourceGraphic" />
                    </filter>
                </defs>
                <circle fill="#B2D2E4" r={size / 2} cx={size / 2} cy={size / 2} />
                {/* This filter thing causes lots of FPS issues */}
                {/* <use filter={`url(#${id}-inset-shadow)`} xlinkHref={`#${id}-circle`} fill="#7099B5" clipPath={`url(#${id}-circleClip)`} /> */}
            </svg>
        </>
    )
}

export { Position, AnimatedPosition }
