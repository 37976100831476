import { Color } from '../game/colors'
import { FetchRequest } from './useFetch'

const GTOONS_HTTP_URL = process.env.REACT_APP_GTOONS_HTTP_URL
const GTOONS_WS_URL = process.env.REACT_APP_GTOONS_WS_URL

export function getCards(): FetchRequest<Card[]> {
    return {
        input: `${GTOONS_HTTP_URL}/api/v1/cards`,
    }
}

const empty: Card[] = []

export function getCardsWithIds(ids: string[]): FetchRequest<Card[]> {
    return {
        input: `${GTOONS_HTTP_URL}/api/v1/cards?ids=${ids.join(',')}`,
        overrideResponse: ids.length === 0 ? empty : undefined,
    }
}

export function newGame(): FetchRequest<string> {
    return {
        input: `${GTOONS_HTTP_URL}/api/v1/game/play`,
        init: { method: 'POST' },
    }
}

export type PlayType = (id: string, username: String) => WebSocket

export const computer: PlayType = (id, username) =>
    new WebSocket(
        `${GTOONS_WS_URL}/api/v1/game/computer?id=${id}&name=${username}&clientTime=${new Date().toISOString()}`
    )

export const pvp =
    (gameId: string): PlayType =>
    (id, username) =>
        new WebSocket(
            `${GTOONS_WS_URL}/api/v1/game/pvp?game=${gameId}&id=${id}&name=${username}&clientTime=${new Date().toISOString()}`
        )

export const debug = (player: string, opponent: string) =>
    new WebSocket(`${GTOONS_WS_URL}/api/v1/debug/game?player=${player}&opponent=${opponent}`)

export function playGtoons(username: string, id: string, type: PlayType): Play {
    const ws = type(id, username)

    var eventBuffer: ServerEvent[] = []
    var waiting: ((e: ServerEvent) => void) | undefined

    ws.onopen = () => handleServerEvent({ type: 'connected' })
    ws.onmessage = (m) => handleServerEvent(JSON.parse(m.data) as ServerEvent)
    ws.onclose = (e) => {
        var reason: ClosedEventReason = 'normal'
        switch (e.reason) {
            case 'game not found':
                reason = 'game_not_found'
                break
            case 'game already started':
                reason = 'game_already_started'
                break
            case 'unexpected error':
                reason = 'unexpected_error'
                break
            default:
                break
        }

        if (!e.wasClean) {
            reason = 'unexpected_error'
        }

        handleServerEvent({ type: 'closed', reason })
    }
    ws.onerror = (e) => {
        console.log('on error')
        console.log(e)
        handleServerEvent({ type: 'closed', reason: 'unexpected_error' })
    }

    function handleServerEvent(event: ServerEvent) {
        if (waiting) {
            waiting(event)
        } else {
            eventBuffer.push(event)
        }
    }

    return {
        next: () => {
            // check for event in event buffer, if none wait.
            if (eventBuffer.length > 0) {
                return Promise.resolve(eventBuffer.shift() as ServerEvent)
            } else {
                return new Promise<ServerEvent>((resolve) => {
                    waiting = resolve
                }).then((e) => {
                    waiting = undefined
                    return e
                })
            }
        },
        send: (e: PlayerEvent) => {
            ws.send(JSON.stringify(e))
        },
        done: () => {
            ws.close()
        },
    }
}

export interface Play {
    next: () => Promise<ServerEvent>
    send: (e: PlayerEvent) => void
    done: () => void
}

// Types from Server
export interface Card {
    id: string
    name: string
    points: number
    color: Color
    description: string
    types: string[]
    character: string
    group?: string
    images: { [key: string]: string }
    rarity: 'Common' | 'Uncommon' | 'Rare' | 'Slam'
}

export interface Modification {
    id: string
    target: BoardPosition
    source: BoardPosition
    modifier: {
        property: 'Points' | 'Color'
        action: 'Add' | 'Mult' | 'Div' | 'Set' | 'Revert'
        value: Color | number
    }
    previous: Color | number
    current: Color | number
}

export type Board = {
    position: string
    available: boolean
    card?: Card
    revealed?: boolean
    disabled?: boolean
    modifications: Modification[]
}[]

export interface Players<T> {
    player: T
    opponent: T
}

export interface GameState {
    playerId: string
    phase: string
    points: Players<number>
    colors: Players<{ color: string; score: number }[]>
    names: Players<string>
    decks: Players<{ name: string; remaining: number }>
    hand: Card[]
    playerIsReady: boolean
    board: Board
}

export const AllPhases = [
    'CONNECTING',
    'DECK_SELECT',
    'INITIAL',
    'DRAW_1',
    'PLAY_1',
    'SCORING_1',
    'DISCARD',
    'DRAW_2',
    'PLAY_2',
    'SCORING_2',
    'SWAP',
    'SILVER',
    'SCORING_3',
    'GAME_OVER',
] as const
export type Phase = typeof AllPhases[number]

export const PlayerPositions = [
    'PLAYER_ONE',
    'PLAYER_TWO',
    'PLAYER_THREE',
    'PLAYER_FOUR',
    'PLAYER_FIVE',
    'PLAYER_SIX',
    'PLAYER_SEVEN',
] as const

export const OpponentPositions = [
    'OPPONENT_ONE',
    'OPPONENT_TWO',
    'OPPONENT_THREE',
    'OPPONENT_FOUR',
    'OPPONENT_FIVE',
    'OPPONENT_SIX',
    'OPPONENT_SEVEN',
] as const

export const AllBoardPositions = [...PlayerPositions, ...OpponentPositions] as const
export type BoardPosition = typeof AllBoardPositions[number]
export function isPlayer(position: BoardPosition): boolean {
    return position.startsWith('PLAYER_')
}

export function isOpponent(position: BoardPosition): boolean {
    return !isPlayer(position)
}

export const AllHandPositions = ['HAND_ONE', 'HAND_TWO', 'HAND_THREE', 'HAND_FOUR', 'HAND_FIVE', 'HAND_SIX'] as const
export type HandPosition = typeof AllHandPositions[number]
export type Position = HandPosition | BoardPosition

export interface ReadyEvent {
    type: 'ready'
}
export interface PlayEvent {
    type: 'play'
    cards: string[]
}
export interface DiscardEvent {
    type: 'discard'
    cards: string[]
}
export interface SwapEvent {
    type: 'swap'
    card: string
}
export interface SilverEvent {
    type: 'silver'
    color: Color
}

export interface DeckSelect {
    type: 'deck-select'
    name: string
    cards: string[]
}

export interface DeckSelectRandom {
    type: 'deck-select-random'
}

export type PlayerEvent =
    | ReadyEvent
    | PlayEvent
    | DiscardEvent
    | SwapEvent
    | SilverEvent
    | DeckSelect
    | DeckSelectRandom

export type ServerEvent =
    | DeckSelectStart
    | InitialEvent
    | DrawEvent
    | PlayStartEvent
    | ScoreEvent
    | DiscardStartEvent
    | SwapStartEvent
    | SilverStartEvent
    | GameOverEvent
    | ConnectedEvent
    | ClosedEvent
export interface StateEvent {
    event: 'state'
    id: string
    names: Players<string>
    points: Players<number>
    decks: Players<{ name: string; remaining: number }>
    hand: Players<Card[]>
    isReady: boolean
    phase: string
    board: Board
}

export interface ConnectedEvent {
    type: 'connected'
}

export interface DeckSelectStart {
    type: 'deck-select-start'
    timestamp: string
}

export interface InitialEvent {
    type: 'initial'
    id: string
    names: Players<string>
    decks: Players<{ name: string; remaining: number }>
    win: 'none' | 'one' | 'two'
    cutCards: Players<Card>
    colors: Color[]
}

export interface DrawEvent {
    type: 'draw'
    phase: 'DRAW_1' | 'DRAW_2'
    cards: Card[]
    opponentDrawCount: number
}

export interface PlayStartEvent {
    type: 'play-start'
    phase: 'PLAY_1' | 'PLAY_2'
    timestamp: string
}

export interface RevealResult {
    position: string
    card: Card
    modifications: Modification[]
    disables: BoardPosition[]
}

export interface ScoreEvent {
    type: 'score'
    phase: 'SCORING_1' | 'SCORING_2' | 'SCORING_3'
    revealResults: RevealResult[]
    swapResult: 'none' | 'player' | 'opponent' | 'both'
}

export interface DiscardStartEvent {
    type: 'discard-start'
    timestamp: string
}

export interface SwapStartEvent {
    type: 'swap-start'
    timestamp: string
}

export interface SilverStartEvent {
    type: 'silver-start'
    timestamp: string
    lastCardColor: Color
}

export interface GameOverEvent {
    type: 'game-over'
    winner: 'player' | 'opponent' | undefined
    reason: 'by-points' | 'by-color' | 'tie' | 'disconnect' | 'cancelled' | 'by-timeout'
    bonus: Players<boolean>
    finalPoints: Players<number>
}

export interface ClosedEvent {
    type: 'closed'
    reason: ClosedEventReason
}

type ClosedEventReason = 'normal' | 'game_not_found' | 'unexpected_error' | 'game_already_started'
