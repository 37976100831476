import { animated, AnimatedProps, config, useSpring } from '@react-spring/web'
import { FunctionComponent, SVGAttributes, useContext, useEffect } from 'react'
import { Modification } from '../../apis/gtoons'
import { useGameSelector } from '../../state/game'
import { CARD_SIZE, GAME_HEIGHT, GAME_WIDTH } from '../constants'
import { positionToCoordinates } from '../coordinates'
import { FONT } from '../styling'
import GameToolsContext from './GameTools'

const DELAY = 300
const sourceSpringProps = {
    reset: true,
    from: { opacity: 0 },
    to: [{ opacity: 1 }, { opacity: 0 }],
    config: config.gentle,
}

const targetSpringProps = {
    reset: true,
    from: {
        fontSize: 0,
        transform: 'translateY(50px)',
        opacity: 1,
    },
    to: [
        { fontSize: 44, transform: 'translateY(0px)', opacity: 1 },
        { fontSize: 44, transform: 'translateY(0px)', opacity: 0 },
    ],
    config: config.wobbly,
    delay: DELAY,
}

const ModificationAnimations: FunctionComponent = () => {
    const latest = useGameSelector((s) => s.latestModification)
    const { playSound } = useContext(GameToolsContext)
    const [sourceSpring] = useSpring(sourceSpringProps, [latest?.id])
    const [targetSpring] = useSpring(targetSpringProps, [latest?.id])

    // play sounds
    useEffect(() => {
        if (!latest) return
        const goodOrBad = isGoodOrBad(latest)

        playSound('whooom')
        setTimeout(() => playSound(goodOrBad === 'good' ? 'blop' : 'thump'), DELAY)
        // eslint-disable-next-line
    }, [latest])

    if (!latest) return <></>

    const source = positionToCoordinates(latest.source)
    const sourceProps: AnimatedProps<SVGAttributes<SVGCircleElement>> = {
        cx: source.x + CARD_SIZE / 2,
        cy: source.y + CARD_SIZE / 2,
        r: CARD_SIZE / 2,

        style: sourceSpring,

        stroke: 'white',
        strokeWidth: 8,
        fillOpacity: 0,
    }

    const target = positionToCoordinates(latest.target)
    const targetConfig = toTargetConfig(latest)
    const targetProps: AnimatedProps<SVGAttributes<SVGTextElement>> = {
        x: target.x + CARD_SIZE / 2,
        y: target.y + 20,

        fill: targetConfig?.fill,
        fontFamily: FONT,
        fontWeight: 'bold',
        dominantBaseline: 'middle',
        fontSize: '44',
        fontStyle: 'italic',
        textAnchor: 'middle',
        stroke: 'black',
        strokeWidth: '1.5',

        style: targetSpring,
    }

    return (
        <svg width={GAME_WIDTH} height={GAME_HEIGHT} x={0} y={0} style={{ pointerEvents: 'none' }}>
            <animated.circle {...sourceProps} />
            {targetConfig && <animated.text {...targetProps}>{targetConfig.text}</animated.text>}
        </svg>
    )
}

function isGoodOrBad(modification: Modification): 'good' | 'bad' {
    const { modifier } = modification
    const { property, action, value } = modifier

    if (property === 'Points' && action === 'Add' && value < 0) return 'bad'
    if (property === 'Points' && action === 'Div') return 'bad'

    return 'good'
}

function toTargetConfig(modification: Modification): { text: string; fill: string } | undefined {
    const { property, value } = modification.modifier
    if (property !== 'Points' || typeof value !== 'number') return undefined
    const isNegative = value < 0
    const fill = isGoodOrBad(modification) === 'good' ? 'green' : 'red'
    const labels = {
        Add: {
            text: `${isNegative ? '' : '+'}${value}`,
            fill,
        },
        Mult: {
            text: 'x' + value,
            fill,
        },
        Div: {
            text: '/' + value,
            fill,
        },
        Set: undefined,
        Revert: undefined,
    }

    return labels[modification.modifier.action]
}

export default ModificationAnimations
