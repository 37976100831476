import { FunctionComponent, HTMLAttributes } from 'react'
import styles from '../Lobby.module.css'

type LobbyPanelArgs = {
    title: string
} & HTMLAttributes<HTMLDivElement>
const LobbyPanel: FunctionComponent<LobbyPanelArgs> = ({ children, title, ...props }) => {
    return (
        <div className={styles.panel + ' ' + props.className || ''}>
            <h1 className={styles.title}>{title}</h1>
            <div className={styles['panel-children']}>{children}</div>
        </div>
    )
}

export default LobbyPanel
