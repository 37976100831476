import { FunctionComponent, useState } from 'react'
import { Message } from '../../state/lobby'
import styles from '../Lobby.module.css'
import Username from './Username'

type ChatProps = { messages: Message[]; send: (content: string) => void }
const Chat: FunctionComponent<ChatProps> = ({ messages, send }) => {
    const [text, setText] = useState('')
    const canSend = text !== ''

    return (
        <div className={styles['chat-container']}>
            <div className={styles['chat-spacer']}></div>
            <div className={styles.messages}>
                {messages.map((m) => (
                    <MessageView key={m.id} message={m} />
                ))}
            </div>
            <div className={styles['chat-input']}>
                <div className={styles['chat-textbox']}>
                    <textarea value={text} onChange={(e) => setText(e.currentTarget.value)} />
                </div>
                <div
                    className={styles['chat-send']}
                    onClick={() => {
                        canSend && send(text)
                        setText('')
                    }}
                >
                    Send
                </div>
            </div>
        </div>
    )
}

type MessageProps = { message: Message }
const MessageView: FunctionComponent<MessageProps> = ({ message }) => {
    return (
        <div className={styles.message}>
            <Username username={message.messenger} /> {message.content}
        </div>
    )
}

export default Chat
