import { useTransition } from '@react-spring/core'
import { animated } from '@react-spring/web'
import { FunctionComponent, SVGAttributes, useContext, useState } from 'react'
import { FaDice } from 'react-icons/fa'
import { useDebounce } from 'use-debounce'
import { Card, getCardsWithIds } from '../../apis/gtoons'
import { useFetch } from '../../apis/useFetch'
import { times } from '../../common/utils'
import { Deck, isComplete } from '../../deck-builder/deck'
import { useGameSelector } from '../../state/game'
import { useUser } from '../../state/user'
import { GAME_HEIGHT, GAME_WIDTH } from '../constants'
import { FONT } from '../styling'
import DeckComponent from './Deck'
import GameToolsContext from './GameTools'
import NamePlate from './NamePlate'
import StatusBar from './StatusBar'

const DeckSelect: FunctionComponent = () => {
    const phase = useGameSelector((s) => s.phase)
    const { username, decks } = useUser()
    const [detail, setDetail] = useState<Deck | undefined>()
    const [debouncedDetail] = useDebounce(detail, 50)
    const request = getCardsWithIds(debouncedDetail?.cards || [])
    const [loading, cards] = useFetch(request)
    const { sendUIEvent } = useContext(GameToolsContext)

    if (phase !== 'DECK_SELECT' && phase !== 'CONNECTING') return <></>

    return (
        <svg width={GAME_WIDTH} height={GAME_HEIGHT} x={0} y={0}>
            <AnimatedDeckSelectStatusBar cx={GAME_WIDTH / 2} cy={200} />
            <DeckList
                username={username}
                decks={phase === 'CONNECTING' ? [] : decks}
                x={250}
                y={230}
                setDetail={setDetail}
                selectDeck={(d) => sendUIEvent({ type: 'deck', deck: d })}
            />
            <DeckDetail x={484} y={230} cards={phase === 'CONNECTING' || loading ? undefined : cards} />
        </svg>
    )
}

type DeckListProps = {
    username?: string
    decks: Deck[]
    setDetail: (deck?: Deck) => void
    selectDeck: (d: Deck | 'random') => void
} & SVGAttributes<SVGSVGElement>

const DeckList: FunctionComponent<DeckListProps> = ({ x, y, decks, username, setDetail, selectDeck }) => {
    return (
        <svg x={x} y={y} width={200} height={320}>
            <rect width={200} height={320} fill="url(#drawer-background)" rx={8} ry={8} />
            <rect width="200" height="40" x={0} y={14} fill="#7099b5" />
            <rect width="200" height="40" x={0} y={64} fill="#7099b5" />
            <rect width="200" height="40" x={0} y={114} fill="#7099b5" />
            <rect width="200" height="40" x={0} y={164} fill="#7099b5" />

            {decks.slice(0, 4).map((d, i) => (
                <g
                    key={i}
                    style={{
                        cursor: isComplete(d) ? 'pointer' : 'default',
                    }}
                    onMouseOver={() => setDetail(d)}
                    onMouseOut={() => setDetail(undefined)}
                    onMouseDown={() => isComplete(d) && selectDeck(d)}
                >
                    <rect width="200" height="40" x={0} y={14 + 50 * i} fill="#7099b5" />
                    <DeckComponent
                        x={10}
                        y={14 + 50 * i}
                        state={{ name: d.name, remaining: d.cards.length }}
                        fillOpacity={isComplete(d) ? 1 : 0.5}
                    />
                </g>
            ))}

            <svg
                x={0}
                y={214}
                width={200}
                height={40}
                style={{ cursor: 'pointer' }}
                onMouseDown={() => selectDeck('random')}
            >
                <rect width="200" height="40" fill="#7099b5" />
                <FaDice x={30} y={10} color="white" size={20} />
                <text x={60} y={23} dominantBaseline="middle" fontSize="14px" fill="white" fontWeight="bold">
                    Random Deck
                </text>
            </svg>

            <NamePlate x={8} y={270} username={username} />
        </svg>
    )
}

type DeckDetailProps = {
    cards?: Card[]
} & SVGAttributes<SVGSVGElement>

const DeckDetail: FunctionComponent<DeckDetailProps> = ({ x, y, cards }) => {
    const textOptions: SVGAttributes<SVGTextElement> = {
        fontSize: '10px',
        fontFamily: FONT,
        fontWeight: 'bold',
        fill: 'white',
        dominantBaseline: 'middle',
    }

    return (
        <svg x={x} y={y} width={266} height={402}>
            <path
                fill="url(#drawer-background)"
                d="M16 0 250 0C258.8366-0 266 7.1634 266 16L266 391.2787C266 395.697 262.4183 399.2787 258 399.2787C257.2288 399.2787 256.4617 399.1672 255.7224 398.9476L5.7224 324.6995C2.3279 323.6914 0 320.5716 0 317.0306L0 16C0 7.1634 7.1634 0 16 0Z"
            />
            <svg x={14} y={14} width={238} height={288}>
                <rect x={0} y={0} width="100%" height="100%" fill="url(#panel-background)" rx={8} ry={8} />
                <>
                    <text x={8} y={12} {...textOptions} fill="#A2F5F5">
                        Name
                    </text>
                    <text x={200} y={12} {...textOptions} textAnchor="middle" fill="#A2F5F5">
                        Clr
                    </text>
                    <text x={230} y={12} {...textOptions} textAnchor="end" fill="#A2F5F5">
                        Pts
                    </text>
                    <rect x={0} y={22} width={238} height={2} fill="#A2F5F5" />
                </>
                {cards &&
                    cards.map((c, i) => (
                        <g key={c.id}>
                            <text x={8} y={36 + i * 22} {...textOptions}>
                                {c.name}
                            </text>

                            <circle cx={200} cy={35 + i * 22} r={5} fill={`url(#${c.color})`} />

                            <text x={230} y={36 + i * 22} {...textOptions} textAnchor="end">
                                {c.points}
                            </text>
                        </g>
                    ))}

                {times(11).map((i) => (
                    <rect key={i} x={0} y={23 + (i + 1) * 22} width={238} height={1} fill="#A2F5F5" />
                ))}
            </svg>
        </svg>
    )
}

const AnimatedDeckSelectStatusBar: FunctionComponent<LoadingStatusBarProps> = (props) => {
    const transitions = useTransition(true, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
    })

    return transitions(
        (style, item) =>
            item && (
                <animated.g style={style}>
                    <LoadingStatusBar {...props} />
                </animated.g>
            )
    )
}

type LoadingStatusBarProps = {
    cx?: number
    cy?: number
}

const LoadingStatusBar: FunctionComponent<LoadingStatusBarProps> = ({ cx = 0, cy = 0 }) => {
    const ready = useGameSelector((s) => s.playerIsReady)
    const phase = useGameSelector((s) => s.phase)
    const timer = useGameSelector((s) => s.timer)

    var message, width, showTimer
    switch (phase) {
        case 'DECK_SELECT':
            message = 'Choose Your Deck'
            width = 170
            showTimer = true
            break
        case 'CONNECTING':
            message = 'Connecting...'
            width = 140
            showTimer = false
            break
        default:
            message = ''
            width = 0
            showTimer = false
            break
    }

    if (ready) {
        message = 'Waiting for Opponent...'
        width = 210
        showTimer = false
    }

    return (
        <StatusBar
            cx={cx}
            cy={cy}
            width={width}
            action={'none'}
            message={message}
            timer={showTimer ? timer : undefined}
        />
    )
}

export default DeckSelect
