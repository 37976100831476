import { FunctionComponent } from 'react'
import animal from '../resources/images/types/animal.svg'
import female from '../resources/images/types/female.svg'
import hero from '../resources/images/types/hero.svg'
import male from '../resources/images/types/male.svg'
import monster from '../resources/images/types/monster.svg'
import place from '../resources/images/types/place.svg'
import prop from '../resources/images/types/prop.svg'
import vehicle from '../resources/images/types/vehicle.svg'
import villain from '../resources/images/types/villain.svg'

type CardTypeProps = {
    type: string
    size?: string
    color?: string
}

export const CardType: FunctionComponent<CardTypeProps> = ({ type, size, color }) => {
    const props = {
        width: size,
        height: size,
        color,
    }

    if (type === 'Animal') return <img src={animal} title="Animal" alt="Animal" {...props} />
    if (type === 'Female') return <img src={female} title="Female" alt="Female" {...props} />
    if (type === 'Hero') return <img src={hero} title="Hero" alt="Hero" {...props} />
    if (type === 'Male') return <img src={male} title="Male" alt="Male" {...props} />
    if (type === 'Monster') return <img src={monster} title="Monster" alt="Monster" {...props} />
    if (type === 'Place') return <img src={place} title="Place" alt="Place" {...props} />
    if (type === 'Prop') return <img src={prop} title="Prop" alt="Prop" {...props} />
    if (type === 'Vehicle') return <img src={vehicle} title="Vehicle" alt="Vehicle" {...props} />
    if (type === 'Villain') return <img src={villain} title="Villain" alt="Villain" {...props} />
    return <></>
}
