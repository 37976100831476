import useSound from 'use-sound'
import audio from '../resources/sounds/audio-sprites.mp3'
import { usePreferencesSelector } from '../state/preferences'

export default function useGameAudio() {
    const enableSounds = usePreferencesSelector((u) => u.sounds)
    const [play] = useSound(audio, {
        sprite: spriteMap as any,
    })

    return (id: keyof typeof spriteMap) => enableSounds && play({ id: id })
}

const spriteMap = {
    'bump-1': [100, 650],
    thud: [800, 320],
    whirr: [1200, 430],
    'tap-tap': [1700, 530],
    clunk: [2300, 420],
    blop: [2800, 530],
    fwoop: [3400, 480],
    swish: [4000, 320],
    'woo-up': [4400, 630],
    knock: [5100, 430],
    blip: [5600, 220],
    'knock-2': [5900, 320],
    thump: [6300, 580],
    'swish-2': [7000, 370],
    whooom: [7500, 890],
    'tap-tap-tap': [8500, 480],
    shuffle: [9100, 2150],
    'clunk-2': [11300, 530],
}
