import classNames from 'classnames'
import { FunctionComponent, HTMLAttributes, SVGAttributes, useState } from 'react'
import { IoCheckmarkCircle, IoRemoveCircle } from 'react-icons/io5'
import { useHistory, useParams } from 'react-router'
import { Card as CardDTO, getCards, getCardsWithIds } from '../apis/gtoons'
import { useFetch } from '../apis/useFetch'
import Card from '../common/Card'
import { CardType } from '../common/CardType'
import { times } from '../common/utils'
import { useDeck } from './deck'
import styles from './DeckBuilderEdit.module.css'

const DeckBuilderEdit: FunctionComponent = () => {
    const { id } = useParams<{ id: string }>()
    const { deck, add, remove, save } = useDeck(id)
    const history = useHistory()
    const [, cards] = useFetch(getCardsWithIds(deck?.cards || []))
    const [filter, setFilter] = useState<CardFilter>({ search: '' })
    if (!deck) {
        history.replace('/home')
        return <></>
    }

    return (
        <div className={styles.main}>
            <Filter searchChanged={(s) => setFilter({ search: s })} />
            <div className={styles['card-list-wrapper']}>
                <CardList filter={filter} selected={deck.cards} add={add} />
            </div>
            <Footer
                remove={remove}
                save={() => {
                    save()
                    history.replace('/deckbuilder')
                }}
                cancel={() => history.replace('/deckbuilder')}
                cards={times(12).map((i) => (cards && cards[i]) || null)}
            />
        </div>
    )
}

type CardFilter = {
    search: string
}

function withFilter(cards: CardDTO[], filter: CardFilter): CardDTO[] {
    const { search } = filter

    return cards.filter((c) => {
        return (
            c.character.toLowerCase().includes(search.toLowerCase()) ||
            c.color.toLowerCase().includes(search.toLowerCase()) ||
            c.group?.toLowerCase().includes(search.toLowerCase()) ||
            c.name.toLowerCase().includes(search.toLowerCase()) ||
            c.types.some((t) => t.toLowerCase().includes(search.toLowerCase())) ||
            c.rarity.toLowerCase().includes(search.toLowerCase()) ||
            c.description.toLowerCase().includes(search.toLowerCase()) ||
            `${c.points}`.includes(search.toLowerCase())
        )
    })
}

export default DeckBuilderEdit

const CardList: FunctionComponent<{
    selected: string[]
    add: (id: string) => void
    filter: CardFilter
}> = ({ selected, add, filter }) => {
    const [, cards] = useFetch(getCards())

    return (
        <div className={styles['card-list']}>
            {withFilter(cards || [], filter)
                .filter((c) => !selected.includes(c.id))
                .map((card) => (
                    <CardView
                        onClick={() => !selected.includes(card.id) && add(card.id)}
                        key={card.id}
                        card={card}
                        selected={selected.includes(card.id)}
                    />
                ))}
        </div>
    )
}

type CardViewProps = {
    card: CardDTO
    selected: boolean
} & HTMLAttributes<HTMLDivElement>
const CardView: FunctionComponent<CardViewProps> = ({ card, selected, ...props }) => {
    return (
        <div className={classNames(styles.card, selected && styles['card-selected'])} {...props}>
            {selected && <IoCheckmarkCircle className={styles.icon} />}
            <Card className={styles['card-image']} card={card} />
            <div className={styles['card-label']}>
                <div className={styles['card-top']}>
                    <div className={styles['card-title']}>{card.name}</div>
                    <div>
                        {card.types.map((t) => (
                            <CardType key={t} type={t} size={'20px'} />
                        ))}
                    </div>
                </div>
                <div className={styles['card-subtitle']}>{card.description}</div>
            </div>
        </div>
    )
}

type FilterProps = {
    searchChanged?: (s: string) => void
}

const Filter: FunctionComponent<FilterProps> = ({ searchChanged }) => {
    return (
        <div className={styles.filter}>
            <input
                type="search"
                className={styles.search}
                size={40}
                onChange={(s) => searchChanged && searchChanged(s.target.value)}
            />
        </div>
    )
}

type FooterProps = {
    cards: (CardDTO | null)[]
    remove: (id: string) => void
    save: () => void
    cancel: () => void
}

const Empty: FunctionComponent<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 100 100">
            <circle cx={50} cy={50} r={50} fill={'#83A2BB'} />
        </svg>
    )
}

const Footer: FunctionComponent<FooterProps> = ({ cards, remove, save, cancel }) => {
    return (
        <div className={styles.footer}>
            <div className={styles.deck}>
                <div className={styles['deck-count']}>{cards.filter((c) => c !== null).length}/12</div>
                {cards.map((c, i) =>
                    !!c ? (
                        <div key={c.id} className={styles['deck-card']}>
                            <IoRemoveCircle onClick={() => remove(c.id)} className={styles.icon} />
                            <Card card={c} />
                        </div>
                    ) : (
                        <div key={`${i}`} className={styles['deck-card']}>
                            <Empty className={styles['deck-card']} />
                        </div>
                    )
                )}
            </div>
            <div className={styles.options}>
                <div className={styles.option} onClick={save}>
                    Save
                </div>
                <div className={styles.option} onClick={cancel}>
                    Cancel
                </div>
            </div>
        </div>
    )
}
