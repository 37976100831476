import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { ChallengeDTO, connectToLobby, Lobby, MessageDTO, PersonDTO } from '../apis/lobby'
import { actions, Challenge, Message, Person } from '../state/lobby'
import { useUser } from '../state/user'

export default function useLobby() {
    const user = useUser()
    const lobby = useRef<Lobby>()
    const dispatch = useDispatch()

    useEffect(() => {
        if (user.id === undefined || user.username === undefined) {
            console.error('invalid user info <%j>', user)
            return
        }

        lobby.current = connectToLobby(user.id, user.username)

        const challenge: (dto: ChallengeDTO) => Challenge = (dto) => ({
            id: dto.id,
            opponent: dto.challengee.id === user.id ? dto.challenger : dto.challengee,
            type: dto.challengee.id === user.id ? 'challenged' : 'accepted',
        })

        const message: (dto: MessageDTO) => Message = (dto) => ({
            id: dto.id,
            messenger: dto.messager.name,
            content: dto.content,
            date: JSON.stringify(new Date()),
        })

        const person: (dto: PersonDTO) => Person = (dto) => ({
            id: dto.id,
            name: dto.name,
            label: user.id === dto.id ? 'me' : 'can-challenge',
        })

        // event loop
        setTimeout(async () => {
            try {
                while (true) {
                    if (!lobby.current) break
                    const event = await lobby.current.next()

                    if (event.type === 'status') {
                        dispatch(actions.statusChange(event.status))
                    } else if (event.type === 'accepted') {
                        dispatch(actions.challengeAccepted(event.id))
                    } else if (event.type === 'challenges') {
                        dispatch(actions.challengesUpdated(event.challenges.map(challenge)))
                    } else if (event.type === 'message') {
                        dispatch(actions.newMessage(message(event.message)))
                    } else if (event.type === 'people') {
                        dispatch(actions.peopleUpdated(event.people.map(person)))
                    } else {
                        console.warn('unsupported event <%j>', event)
                    }
                }
            } catch (err) {
                console.error(err)
            }
        }, 0)

        return () => {
            dispatch(actions.reset())
            lobby.current?.done()
        }
    }, [user, lobby, dispatch])

    return {
        sendMessage: (content: string) => {
            lobby.current?.send({ type: 'message', content })
        },
        issueChallenge: (challengee: string) => {
            lobby.current?.send({ type: 'challenge', person: challengee })
        },
        acknowledgeChallenge: (id: string, response: 'accept' | 'decline') => {
            lobby.current?.send({ type: response, challenge: id })
        },
    }
}
