import { useState } from 'react'
import { BsChevronDown } from 'react-icons/bs'
import { FaDice } from 'react-icons/fa'
import onClickOutside from 'react-onclickoutside'
import { usePopper } from 'react-popper'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import Logo from '../common/Logo'
import { usernameUpdated, useUserSelector } from '../state/user'
import styles from './Setup.module.css'
import { useUsername } from './username'

function Setup() {
    const current = useUserSelector((u) => u.username)?.split(' ')
    const [usernameOptions, randomUsername] = useUsername()

    const [defaultFirstName, defaultSecondName, defaultThirdName] = randomUsername()

    const [firstName, setFirstName] = useState((current && current[0]) || defaultFirstName)
    const [secondName, setSecondName] = useState((current && current[1]) || defaultSecondName)
    const [thirdName, setThirdName] = useState((current && current[2]) || defaultThirdName)

    const shuffle = () => {
        const [newFirstName, newSecondName, newThirdName] = randomUsername()
        setFirstName(newFirstName)
        setSecondName(newSecondName)
        setThirdName(newThirdName)
    }

    const dispatch = useDispatch()

    return (
        <>
            <Logo className={styles.logo} />
            <div className={styles['username-wrapper']}>
                <strong className={styles['username-label']}>Select a username</strong>
                <div className={styles['username-select']}>
                    <DropdownWithClickOutside
                        title="First Name"
                        items={usernameOptions.firstNames}
                        selected={firstName}
                        onSelectionChange={setFirstName}
                    />
                    <DropdownWithClickOutside
                        title="Second Name"
                        items={usernameOptions.secondNames}
                        selected={secondName}
                        onSelectionChange={setSecondName}
                    />
                    <DropdownWithClickOutside
                        title="Third Name"
                        items={usernameOptions.thirdNames}
                        selected={thirdName}
                        onSelectionChange={setThirdName}
                    />
                </div>
                <div className={styles['setup-form']}>
                    <div className={styles['shuffle-username']} onClick={shuffle}>
                        Shuffle
                        <FaDice />
                    </div>
                    <Link
                        to="/home"
                        className={styles['submit']}
                        onClick={() => dispatch(usernameUpdated(`${firstName} ${secondName} ${thirdName}`))}
                    >
                        OK
                    </Link>
                </div>
            </div>
        </>
    )
}

function Dropdown({ title, items, selected, onSelectionChange, open, setOpen }) {
    const [dropdownHeader, setDropdownHeader] = useState(null)
    const [dropdownList, setDropdownList] = useState(null)

    const { styles: popperStyles, attributes } = usePopper(dropdownHeader, dropdownList)

    return (
        <div className={styles['dropdown-wrapper']}>
            <div className={styles['dropdown-header']} ref={setDropdownHeader} onClick={() => setOpen(!open)}>
                {selected || title}
                <BsChevronDown />
            </div>
            {open && (
                <ul
                    className={styles['dropdown-list']}
                    ref={setDropdownList}
                    style={{
                        ...popperStyles.popper,
                        width: dropdownHeader?.clientWidth,
                    }}
                    {...attributes.popper}
                >
                    {items.map((item) => (
                        <li key={item}>
                            <div
                                className={styles['dropdown-item']}
                                type="button"
                                onClick={() => {
                                    onSelectionChange(item)
                                    setOpen(false)
                                }}
                            >
                                {item}
                            </div>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}

const DropdownWithClickOutside = (props) => {
    const [open, setOpen] = useState(false)

    const C = onClickOutside(Dropdown, {
        handleClickOutside: (i) => () => setOpen(false),
    })

    return <C {...props} open={open} setOpen={setOpen} />
}

export default Setup
