import { FunctionComponent, HTMLAttributes } from 'react'
import logo from '../resources/images/logo.svg'

const Logo: FunctionComponent<HTMLAttributes<HTMLImageElement>> = (props) => (
    <>
        <img {...props} alt="gTOONS Remastered" src={logo} />
    </>
)

export default Logo
