import classNames from 'classnames'
import { FunctionComponent, useState } from 'react'
import { HiOutlineClipboard, HiOutlineClipboardCheck } from 'react-icons/hi'
import { Link, useHistory } from 'react-router-dom'
import { useLocation } from 'react-use'
import { newGame } from '../apis/gtoons'
import Logo from '../common/Logo'
import { useUserSelector } from '../state/user'
import discord from './discord.svg'
import styles from './Home.module.css'

const DISCORD_INVITE = process.env.REACT_APP_DISCORD_INVITE

function Home() {
    const username = useUserSelector((s) => s.username)

    return (
        <>
            <Link to="/about">
                <Logo className={styles.logo} />
            </Link>
            <div className={styles.wrapper}>
                <h1>
                    Welcome <strong>{username}</strong>!
                </h1>
                <div className={styles.menu}>
                    <Link className={styles.button} to="/lobby">
                        Multiplayer Lobby
                    </Link>
                    <Link className={styles.button} to="/play/computer">
                        Play Computer
                    </Link>
                    <Link className={styles.button} to="/deckbuilder">
                        Deck Builder
                    </Link>
                    <Link className={styles.button} to="/setup">
                        Change Username
                    </Link>
                    <div>
                        <a href={DISCORD_INVITE} target="_blank" rel="noreferrer noopener">
                            <img className={styles.discord} src={discord} alt="Join the discord server!" />
                        </a>
                        <div style={{ paddingTop: '8px', fontSize: '0.75em', color: 'lightgray' }}>
                            <i>Join the Discord to play with others!</i>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

// eslint-disable-next-line
const Versus: FunctionComponent = () => {
    const [state, setState] = useState<string>('init')
    const [copied, setCopied] = useState<boolean>(false)
    const history = useHistory()
    const location = useLocation()

    var button: any
    var link: any
    var click: (() => void) | undefined
    var linkClick: (() => void) | undefined
    switch (state) {
        case 'init':
            button = 'New Game'
            link = ''
            click = () => {
                const doFetch = async () => {
                    try {
                        setState('loading')
                        const { input, init } = newGame()
                        const response = await fetch(input, init)
                        const json = await response.json()
                        setState(json.id)
                    } catch (error) {
                        setState('init')
                    }
                }
                doFetch()
            }
            linkClick = undefined
            break
        case 'loading':
            button = 'Loading'
            link = ''
            click = undefined
            linkClick = undefined
            break
        default:
            button = 'Play'
            link = `${location.origin}/play/${state}`
            click = () => history.push(`/play/${state}`)
            linkClick = () => {
                navigator.clipboard.writeText(link)
                setCopied(true)
            }
            break
    }

    const iconProps = {
        size: 24,
        className: styles['versus-button'],
        opacity: !!linkClick ? 1 : 0.7,
        onClick: !copied && !!linkClick ? linkClick : undefined,
        style: { cursor: !copied && !!linkClick ? 'pointer' : 'default' },
    }

    return (
        <>
            <div className={styles.versus}>
                <input
                    className={classNames(styles['versus-link'], {
                        [styles['versus-link-ph']]: !linkClick,
                    })}
                    readOnly={true}
                    value={link}
                    placeholder={`${location.origin}/play/xxxxxxxx-xxxx-xxxx-xxxxxxxx`}
                />
                <div>
                    {copied ? <HiOutlineClipboardCheck {...iconProps} /> : <HiOutlineClipboard {...iconProps} />}
                    <button
                        className={styles['versus-button']}
                        style={{
                            cursor: !!click ? 'pointer' : 'default',
                            minWidth: '100px',
                        }}
                        onClick={click}
                    >
                        {button}
                    </button>
                </div>
            </div>
        </>
    )
}

export default Home
