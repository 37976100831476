import { FunctionComponent } from 'react'
import { selectActionStatus, selectBoardPositions, UIPhase, useGameSelector } from '../../state/game'
import { CARD_SIZE } from '../constants'
import ModificationAnimations from './ModificationAnimations'
import { AnimatedPosition } from './Position'
import StatusBar from './StatusBar'

const GameBoard: FunctionComponent = () => {
    const boardPositions = useGameSelector(selectBoardPositions)
    const phase = useGameSelector((s) => s.phase)

    if (phase === 'CONNECTING' || phase === 'LOADING' || phase === 'DECK_SELECT') return <></>

    return (
        <>
            <g>
                <rect width={912} height={322} x={48} y={48} rx={8} fill="#EDF8FC" />
                <rect width={912} height={278} x={48} y={70} fill="#83A2BB" />
                <rect width={912} height={322} x={48} y={380} rx={8} fill="#EDF8FC" />
                <rect width={912} height={278} x={48} y={402} fill="#83A2BB" />

                {/* Board */}
                {boardPositions.map(({ position, status }) => (
                    <AnimatedPosition key={position} position={position} status={status} size={CARD_SIZE} />
                ))}

                {/* Status Bar */}
                <GameStatusBar cx={510} cy={372} />
            </g>
            <ModificationAnimations />
        </>
    )
}

type GameStatusBarProps = {
    cx?: number
    cy?: number
}

const GameStatusBar: FunctionComponent<GameStatusBarProps> = ({ cx = 0, cy = 0 }) => {
    const action = useGameSelector(selectActionStatus)
    const phase = useGameSelector((s) => s.phase)
    const timer = useGameSelector((s) => s.timer)
    const ready = useGameSelector((s) => s.playerIsReady)

    const phaseConfig: { [p in UIPhase]: { message: string; width: number } } = {
        CONNECTING: { message: '', width: 0 },
        DECK_SELECT: { message: '', width: 0 },
        LOADING: { message: 'Loading Data', width: 170 },
        INITIAL: { message: 'Starting Game', width: 180 },
        DRAW_1: { message: 'Round 1', width: 120 },
        PLAY_1: { message: 'Play 4 GToons', width: 160 },
        SCORING_1: { message: 'Scoring...', width: 100 },
        DISCARD: { message: 'Discard', width: 120 },
        DRAW_2: { message: 'Round 2', width: 120 },
        PLAY_2: { message: 'Play 3 GToons', width: 160 },
        SCORING_2: { message: 'Scoring...', width: 100 },
        SWAP: { message: 'Swap Card?', width: 150 },
        SILVER: { message: 'Choose Color', width: 160 },
        SWAP_YES: { message: 'Swap Card?', width: 150 },
        SCORING_3: { message: 'Scoring...', width: 100 },
        GAME_OVER: { message: 'Game Over!', width: 150 },
    }

    var { message, width } = phaseConfig[phase]
    if (ready && phase !== 'GAME_OVER') {
        message = 'Waiting for Opponent...'
        width = 210
    }

    const showTimer =
        timer !== undefined &&
        !ready &&
        (phase === 'PLAY_1' || phase === 'DISCARD' || phase === 'PLAY_2' || phase === 'SWAP' || phase === 'SWAP_YES')

    return (
        <StatusBar
            cx={cx}
            cy={cy}
            width={width}
            action={action}
            message={message}
            timer={showTimer ? timer : undefined}
        />
    )
}

export default GameBoard
