import * as Bowser from 'bowser'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import { FunctionComponent, useState } from 'react'
import { AiFillBug, AiOutlineLoading } from 'react-icons/ai'
import { useGameSelector } from '../../state/game'
import { useUserSelector } from '../../state/user'

const Debug: FunctionComponent = () => {
    const [hovered, setHovered] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const game = useGameSelector((s) => s)
    const user = useUserSelector((u) => u)

    const click = async () => {
        if (loading) return

        console.log('saving debug information for game', game.id)
        setLoading(true)
        const filename = game.id || 'gtoons-debug-info'

        const zip = new JSZip().folder(filename)

        zip?.file(
            'README.txt',
            'If you encountered an issue, please upload this zip file to the #issues Discord channel on the gTOONS Remastered server.'
        )

        const version = process.env.REACT_APP_VERSION
        const agent = Bowser.parse(window.navigator.userAgent)

        zip?.file('agent.json', JSON.stringify({ ...agent, version }))
        zip?.file('state.json', JSON.stringify({ game, user }))

        const content = await zip?.generateAsync({ type: 'blob' })
        if (content) {
            saveAs(content, `${filename}.zip`)
        }
        setLoading(false)
    }

    let icon = <AiFillBug opacity={hovered ? '90%' : '30%'} color="white" width={'100%'} size={'100%'} />
    if (loading) {
        icon = (
            <g style={{ animation: 'spin 1s linear infinite', transformOrigin: '50% 50%' }}>
                <AiOutlineLoading size={'100%'} color="white" />
            </g>
        )
    }

    return (
        <>
            <text x={740} y={35} dominantBaseline="auto" fontSize="10px" fill="rgba(255,255,255,.50)">
                {game.id || '???'}
            </text>
            <svg
                onMouseOver={() => setHovered(true)}
                onMouseOut={() => setHovered(false)}
                onClick={() => click()}
                x={990}
                y={700}
                style={{ cursor: 'pointer' }}
                width={'1.5em'}
                height={'1.5em'}
            >
                <rect x={0} y={0} width={'100%'} height={'100%'} opacity={'0%'} />
                {icon}
            </svg>
        </>
    )
}

export default Debug
