import { FunctionComponent } from 'react'

const Username: FunctionComponent<{ username: string }> = ({ username }) => {
    const i = hash(username) % colors.length
    const color = colors[i]

    return (
        <span style={{ color: color }}>
            <b>{username}</b>
        </span>
    )
}

function hash(str: string): number {
    var hash = 0,
        i,
        chr
    if (str.length === 0) return hash
    for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i)
        hash = (hash << 5) - hash + chr
        hash |= 0 // Convert to 32bit integer
    }
    return Math.abs(hash)
}

const colors = [
    '#7fffd4',
    '#baf73c',
    '#00ff7f',
    '#90ee90',
    '#36d7b7',
    '#7ed07e',
    '#90c695',
    '#4ecdc4',
    '#66cc99',
    '#65c6bb',
    '#00d46a',
    '#2eccb0',
    '#00d400',
    '#00cf80',
    '#2ecc91',
    '#68c3a3',
    '#76c376',
    '#2ecc71',
    '#03c9a9',
    '#32cd32',
    '#2ecc51',
    '#2ecc32',
    '#3fc380',
    '#8bb82d',
    '#26c281',
    '#1bbc9b',
    '#2abb9b',
    '#e4f1fe',
    '#c5eff7',
    '#00ffff',
    '#00f8fb',
    '#add8e6',
    '#bbd4d4',
    '#00e0e0',
    '#34dbdb',
    '#81cfe0',
    '#89c4f4',
    '#00d4d4',
    '#00ced1',
    '#6bb9f0',
    '#00bfff',
    '#8db0bb',
    '#34b9db',
    '#95aaaa',
    '#19b5fe',
    '#52b3d9',
    '#59abe3',
    '#ffecdb',
    '#dcc6e0',
    '#f1a9a0',
    '#dda0dd',
    '#aea8d3',
    '#d5d5d5',
    '#7bacdd',
    '#91a6ba',
    '#fffacd',
    '#f1f227',
    '#c9f227',
    '#ffd700',
    '#f5d76e',
    '#f4d03f',
    '#f7ca18',
    '#d4d0ab',
    '#f2ca27',
    '#d4b300',
    '#f2a127',
    '#e2a50e',
    '#daa520',
    '#c7a720',
    '#aaa789',
    '#fde3a7',
    '#e6cc22',
    '#f9bf3b',
    '#f4b350',
    '#f5ab35',
    '#ffa07a',
    '#f4a460',
    '#e6a522',
    '#f39c12',
    '#ff7f50',
]

export default Username
