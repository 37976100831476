import { FunctionComponent, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { Card, getCards } from '../apis/gtoons'
import { useFetch } from '../apis/useFetch'
import { gradients } from '../common/Gradient'
import Logo from '../common/Logo'
import { times } from '../common/utils'
import { deckUpdated, useUser } from '../state/user'
import { Deck, deserializeDeck, isComplete, serializeDeck, useDeck } from './deck'
import styles from './DeckBuilder.module.css'

const DeckBuilder: FunctionComponent = () => {
    const [, cards] = useFetch(getCards())
    const history = useHistory()

    return <DeckSelect cards={cards} select={(deck) => history.push(`/deckbuilder/${deck.name}`)} />
}

type Popup = { type: 'import'; deck: string } | { type: 'export'; deck: string } | undefined

type DeckSelectProps = {
    select: (d: Deck) => void
    cards: Card[]
}
const DeckSelect: FunctionComponent<DeckSelectProps> = ({ cards, select }) => {
    const { decks } = useUser()
    const [expanded, setExpanded] = useState<string>('')
    const [popup, setPopup] = useState<Popup>(undefined)

    return (
        <div className={styles['deck-builder']}>
            <Logo className={styles.logo} />
            <h1 className={styles.heading}>Deck Builder</h1>

            {decks.map((d) => (
                <DeckRow
                    key={d.name}
                    deck={d}
                    isExpanded={expanded === d.name}
                    expand={setExpanded}
                    select={select}
                    cards={cards}
                    popup={setPopup}
                />
            ))}

            <Link className={styles['home-button']} to="/home">
                Home
            </Link>

            {popup?.type === 'import' && <Import cards={cards} name={popup.deck} popup={setPopup} />}
            {popup?.type === 'export' && <Export cards={cards} name={popup.deck} popup={setPopup} />}
        </div>
    )
}

const DeckRow: FunctionComponent<
    {
        deck: Deck
        isExpanded: boolean
        expand: (name: string) => void
        popup: (popup: Popup) => void
    } & DeckSelectProps
> = ({ deck, isExpanded, expand, select, cards, popup }) => {
    const cardsInDeck = deck.cards.map((id) => cards?.find((c) => c.id === id)).filter((c) => !!c)

    return (
        <>
            <div className={styles.deck} onClick={() => (isExpanded ? expand('') : expand(deck.name))}>
                <div style={{ padding: '8px', paddingLeft: '16px' }}>{deck.name}</div>
                <div>
                    {times(12).map((i) => (
                        <DeckCountMarker key={i} filled={deck.cards[i] !== undefined} />
                    ))}
                </div>
                <button
                    className={styles['edit-button']}
                    onClick={(e) => {
                        e.stopPropagation()
                        select(deck)
                    }}
                >
                    Edit
                </button>
            </div>
            {isExpanded ? (
                <div className={styles['card-list']}>
                    <div className={styles.share}>
                        <button onClick={() => popup({ type: 'import', deck: deck.name })}>Import</button>
                        <button onClick={() => popup({ type: 'export', deck: deck.name })}>Export</button>
                    </div>
                    <CardList cards={cardsInDeck} />
                </div>
            ) : (
                <></>
            )}
        </>
    )
}

const CardList: FunctionComponent<{ cards: (Card | undefined)[] }> = ({ cards }) => {
    return (
        <>
            {times(12).map((i) => (
                <CardRow key={i} card={cards[i]} />
            ))}
        </>
    )
}

const CardRow: FunctionComponent<{ card?: Card }> = ({ card }) => {
    if (!card) {
        return <div className={`${styles['card-row']} ${styles['card-row-empty']}`}>Empty</div>
    }

    return (
        <div className={styles['card-row']}>
            <svg
                width="10px"
                height="10px"
                viewBox="0 0 10 10"
                version="1.1"
                style={{ verticalAlign: 'top', paddingRight: '4px' }}
                xmlns="http://www.w3.org/2000/svg"
            >
                <defs>{gradients[card.color]}</defs>
                <circle cx={5} cy={5} r={5} fill={`url(#${card.color})`} />
            </svg>
            <div>{card.name}</div>
            <div style={{ flex: '1', textAlign: 'end' }}>{card.points}</div>
        </div>
    )
}

const DeckCountMarker: FunctionComponent<{ filled: boolean }> = ({ filled }) => (
    <svg width="10px" height="22px" viewBox="0 0 10 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="0" width="8" height="22" rx="3" fill={filled ? '#E3EEF4 ' : 'rgba(232, 232, 232, 0.7)'} />
    </svg>
)

type PopupProps = {
    name: string
    cards: Card[]
    popup: (popup: Popup) => void
}

const Import: FunctionComponent<PopupProps> = ({ name, cards, popup }) => {
    const dispatch = useDispatch()
    const [toImport, setToImport] = useState('')

    const deserialized = deserializeDeck(name, toImport)
    const valid = deserialized.cards.every((id) => cards.find((c) => c.id === id) !== undefined)
    const complete = isComplete(deserialized)

    return (
        <div className={styles['import-export']}>
            <textarea onChange={(e) => setToImport(e.target.value)} />
            <div>
                <button
                    disabled={!(valid && complete)}
                    onClick={() => {
                        dispatch(deckUpdated(deserialized))
                        popup(undefined)
                    }}
                >
                    Import
                </button>
                <button onClick={() => popup(undefined)}>Cancel</button>
            </div>
        </div>
    )
}

const Export: FunctionComponent<PopupProps> = ({ name, popup }) => {
    const { deck } = useDeck(name)

    const serialized = serializeDeck(deck)
    return (
        <div className={styles['import-export']}>
            <textarea value={serialized} />
            <div>
                <button onClick={() => popup(undefined)}>Done</button>
            </div>
        </div>
    )
}

export default DeckBuilder
