import { FunctionComponent, SVGAttributes, SVGProps, useContext, useEffect, useState } from 'react'
import { useTimer } from 'react-timer-hook'
import { StatusAction } from '../../state/game'
import colors, { Color } from '../colors'
import styles from '../Game.module.css'
import { FONT } from '../styling'
import GameToolsContext from './GameTools'

type StatusBarProps = {
    cx?: number
    cy?: number
    message: string
    timer?: number
    width: number
    action: StatusAction
}

const StatusBar: FunctionComponent<StatusBarProps> = ({ cx = 0, cy = 0, width, message, timer, action }) => {
    const textOptions = {
        fontSize: '16px',
        fontFamily: FONT,
        // fontStyle: "italic",
        fontWeight: 'bold',
        fill: 'white',
        textAnchor: 'middle',

        dominantBaseline: 'middle',
    }

    var w = width,
        h = 36

    const Text = (
        <text x={w / 2} y={h / 2 + 1} width={w} height={h} {...textOptions}>
            {message}
        </text>
    )

    const timerSize = 100
    if (timer !== undefined) w += timerSize

    var Button = <></>
    if (action === 'ready' || action === 'cancel' || action === 'ready-cancel') {
        Button = (
            <>
                {(action === 'ready' || action === 'ready-cancel') && (
                    <StatusButton x={8 + cx + w / 2} y={cy - 14} width={80} direction="right" type="ready" />
                )}
                {(action === 'cancel' || action === 'ready-cancel') && (
                    <StatusButton x={cx - 88 - w / 2} y={cy - 14} width={80} direction="left" type="cancel" />
                )}
            </>
        )
    } else if (action === 'yes-no') {
        Button = (
            <>
                <StatusButton x={8 + cx + w / 2} y={cy - 14} width={60} direction="right" type="yes" />
                <StatusButton x={cx - 68 - w / 2} y={cy - 14} width={60} direction="left" type="no" />
            </>
        )
    } else if (action === 'colors') {
        const buttons = []
        const bw = 50
        const pad = 6

        const left = ['ORANGE', 'RED', 'SILVER', 'BLACK'] as Color[]
        const right = ['YELLOW', 'GREEN', 'BLUE', 'PURPLE'] as Color[]

        // left
        for (let i = 0; i < left.length; i++) {
            const color = left[i]
            const button = (
                <StatusButton
                    key={color}
                    x={cx - (bw + pad) * (i + 1) - w / 2}
                    y={cy - 14}
                    width={bw}
                    direction="left"
                    type={color}
                />
            )

            buttons.push(button)
        }

        for (let i = 0; i < right.length; i++) {
            const color = right[i]
            const button = (
                <StatusButton
                    key={color}
                    x={cx + bw * i + pad * (i + 1) + w / 2}
                    y={cy - 14}
                    width={bw}
                    direction="right"
                    type={color}
                />
            )

            buttons.push(button)
        }

        Button = <>{buttons}</>
    } else if (action === 'done')
        Button = <StatusButton x={8 + cx + w / 2} y={cy - 14} width={80} direction="right" type="home" />

    return (
        <>
            <svg className={styles['shadow']} x={cx - w / 2} y={cy - h / 2} style={{ overflow: 'visible' }}>
                <rect x={0} y={0} width={w} height={h} fill="#e8e8e8" rx={h / 2} ry={h / 2} />
                <rect
                    x={4}
                    y={4}
                    width={w - 8}
                    height={h - 8}
                    fill="url('#panel-background')"
                    rx={(h - 8) / 2}
                    ry={(h - 8) / 2}
                />
                {Text}
                {timer && <Timer x={w - 30} y={h / 2 + 1.5} width={timerSize} height={h} timestamp={new Date(timer)} />}
            </svg>
            {Button}
        </>
    )
}

type StatusButtonProps = {
    width: number
    x: number
    y: number
    direction: 'left' | 'right'
    type: Color | 'yes' | 'no' | 'home' | 'ready' | 'cancel'
} & SVGAttributes<SVGSVGElement>

const StatusButton: FunctionComponent<StatusButtonProps> = ({ width, x, y, direction, type }) => {
    const [hover, setHover] = useState(false)
    const tools = useContext(GameToolsContext)

    useEffect(() => {
        if (hover) tools.playSound('blip')
    }, [hover, tools])

    const height = 28
    const stroke = 3

    let text: string | undefined = undefined
    let background = <></>

    if (type === 'cancel') text = 'Cancel'
    else if (type === 'home') text = 'Home'
    else if (type === 'yes') text = 'Yes'
    else if (type === 'no') text = 'No'
    else if (type === 'ready') text = 'Ready'
    else {
        if (direction === 'left') {
            background = <rect x={-13} y={-8} width={22} height={16} rx={3} ry={3} fill={`url(#${type})`} />
        } else {
            background = <rect x={-8} y={-8} width={22} height={16} rx={3} ry={3} fill={`url(#${type})`} />
        }
    }

    return (
        <>
            <svg
                className={styles['shadow']}
                x={x}
                y={y}
                width={width}
                height={height}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                    if (type in colors) {
                        tools.sendUIEvent({ type: 'color', color: type as Color })
                    } else {
                        tools.sendUIEvent({ type: type as any })
                    }
                }}
                onMouseOver={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                <defs>
                    <mask id="outer-clip">
                        <rect width="100%" height="100%" fill="white" />
                        <circle cx={-6} cy={height / 2} r={height / 2} fill="black" />
                    </mask>

                    <mask id="inner-clip">
                        <rect width="100%" height="100%" fill="white" />
                        <circle cx={-6 + stroke} cy={height / 2} r={height / 2} fill="black" />
                    </mask>
                </defs>
                <g transform={direction === 'left' ? `scale(-1, 1) translate(-${width}, 0)` : ''}>
                    <rect
                        x={-height}
                        y={0}
                        width={width + height}
                        height={height}
                        fill="#e8e8e8"
                        mask="url(#outer-clip)"
                        ry={height / 2}
                        rx={height / 2}
                    />
                    <rect
                        x={-height + stroke}
                        y={stroke}
                        width={width + height - stroke * 2}
                        height={height - stroke * 2}
                        fill="url(#panel-background)"
                        mask="url(#inner-clip)"
                        ry={(height - stroke * 2) / 2}
                        rx={(height - stroke * 2) / 2}
                    />
                </g>
                <g transform={`translate(${width / 2}, ${height / 2})`} fillOpacity={hover ? '100%' : '80%'}>
                    {background}
                    {text && (
                        <text
                            x={0}
                            y={0}
                            fontSize="12px"
                            dominantBaseline="middle"
                            textAnchor="middle"
                            fontFamily={FONT}
                            fill="white"
                            fontWeight="bold"
                        >
                            {text}
                        </text>
                    )}
                </g>
            </svg>
        </>
    )
}

const Timer: FunctionComponent<{ timestamp: Date } & SVGProps<SVGTextElement>> = ({
    x,
    y,
    width,
    height,
    timestamp,
}) => {
    const { seconds, restart } = useTimer({ expiryTimestamp: timestamp })
    useEffect(() => {
        restart(timestamp)
    }, [timestamp, restart])

    return (
        <text
            x={x}
            y={y}
            width={width}
            height={height}
            dominantBaseline="middle"
            fontFamily={FONT}
            fontWeight="bold"
            fontSize="12px"
            fill="white"
            fillOpacity="80%"
            textAnchor={'end'}
        >
            Time Left: {seconds}
        </text>
    )
}

export default StatusBar
