import { FunctionComponent, SVGAttributes } from 'react'
import { FONT } from '../styling'

type PanelArgs = {
    label: string
} & SVGAttributes<SVGElement>

const Panel: FunctionComponent<PanelArgs> = ({ label, x, y, ...props }) => {
    const textOptions = {
        fontSize: '12px',
        fontFamily: FONT,
        fontStyle: 'italic',
        fontWeight: 'bold',
        fill: '#7099B5',
    }

    return (
        <svg width={150} height={88} x={x} y={y} {...props}>
            <text {...textOptions} y={2} dominantBaseline="hanging">
                {label}
            </text>
            <path
                fill="url('#panel-background')"
                d="M41,16 C47.7149533,16 53.4637557,11.8634165 55.837176,5.99948087 L142,6 C146.418278,6 150,9.581722 150,14 L150,80 C150,84.418278 146.418278,88 142,88 L8,88 C3.581722,88 5.41083001e-16,84.418278 0,80 L0,16 Z"
            />
        </svg>
    )
}

export default Panel
