import { AllBoardPositions, AllHandPositions, BoardPosition, Card, HandPosition, Modification } from '../apis/gtoons'

export type PositionStatus =
    | { type: 'open' }
    | { type: 'closed' }
    | {
          type: 'card'
          card: Card
          disabled: boolean
          modifications: Modification[]
      }
    | { type: 'card-back' }

export type UIPosition = Position | DiscardPosition

export type DiscardPosition = 'DISCARD_ONE' | 'DISCARD_TWO'
export const AllDiscardPositions: DiscardPosition[] = ['DISCARD_ONE', 'DISCARD_TWO']

export const AllPositions: Readonly<Position[]> = [
    ...AllHandPositions,
    ...AllBoardPositions,
    ...AllDiscardPositions,
] as const

export type Position = HandPosition | BoardPosition | DiscardPosition
export type PositionMap = {
    [Property in Position]: PositionStatus
}

export const InitialPositions = () =>
    ({
        ...AllHandPositions.reduce((acc, curr: HandPosition) => ({ ...acc, [curr]: { type: 'open' } }), {}),
        ...AllDiscardPositions.reduce(
            (acc, curr: DiscardPosition) => ({
                ...acc,
                [curr]: { type: 'open' },
            }),
            {}
        ),
        ...AllBoardPositions.reduce(
            (acc, curr: BoardPosition) => ({
                ...acc,
                [curr]: { type: 'closed' },
            }),
            {}
        ),
    } as PositionMap)

export type BoardPositionMap = {
    [Property in BoardPosition]: PositionStatus
}

export type HandPositionMap = {
    [Property in HandPosition]: PositionStatus
}
