import { FunctionComponent, SVGAttributes, useContext, useState } from 'react'
import { useGameSelector } from '../../state/game'
import styles from '../Game.module.css'
import { FONT } from '../styling'
import GameToolsContext from './GameTools'

type ResultsProps = {
    cx?: number
    cy?: number
}

const textOptions = {
    textAnchor: 'middle',
    dominantBaseline: 'hanging',
    fontFamily: FONT,
    fontWeight: 'bold',
}

const Results: FunctionComponent<ResultsProps> = ({ cx = 510, cy = 372 }) => {
    const results = useGameSelector((s) => s.results)
    const [highlighted, setHighlighted] = useState<'done' | 'review' | undefined>()
    const [hidden, setHidden] = useState<boolean>(false)
    const { sendUIEvent } = useContext(GameToolsContext)

    if (results === undefined || hidden) return <></>

    const w = 350
    const h = 200

    let header = ''
    let subtitle = ''

    if (results.type === 'tie') {
        header = 'Tie Game!'
    } else if (results.type === 'cancelled') {
        header = 'Game cancelled'
        subtitle = 'Your opponent left.'
    } else if (results.type === 'error') {
        if (results.error === 'game_not_found') {
            header = 'Game not found.'
            subtitle = 'Try creating a new game!'
        } else if (results.error === 'game_already_started') {
            header = 'Game already started.'
            subtitle = 'Try creating a new game!'
        } else {
            header = 'Uh oh!'
            subtitle = 'Something went wrong!'
        }
    } else if (results.winner === 'player') {
        header = 'Congratulations!'
        if (results.type === 'by-points') subtitle = 'Winner by Points'
        if (results.type === 'by-color') subtitle = 'Winner by Color'
        if (results.type === 'disconnect') subtitle = 'Your opponent left'
        if (results.type === 'by-timeout') subtitle = 'Your opponent ran out of time'
    } else if (results.winner === 'opponent') {
        subtitle = 'Better luck next time.'
        if (results.type === 'by-points') header = 'Game lost by Points.'
        if (results.type === 'by-color') header = 'Game lost by Color.'
        if (results.type === 'by-timeout') header = 'You ran out of time.'
    }

    const stroke = 6

    return (
        <svg className={styles['shadow']} x={cx - w / 2} y={cy - h / 2} width={w} height={h}>
            <rect x={0} y={0} width={w} height={h} fill="#e8e8e8" rx="8" ry="8" />
            <rect
                x={stroke}
                y={stroke}
                width={w - stroke * 2}
                height={h - stroke * 2}
                fill="url(#panel-background)"
                rx="8"
                ry="8"
            />
            <text x={w / 2} y={24} fill="white" fontStyle="italic" fontSize="32px" {...textOptions}>
                {header}
            </text>
            <text x={w / 2} y={75} fill="white" opacity="60%" fontSize="20px" {...textOptions}>
                {subtitle}
            </text>

            <ResultsButton
                text="Home"
                width={160}
                height={32}
                color="#83A2BB"
                fontSize="16px"
                x={w / 2 - 80}
                y={120}
                highlighted={highlighted === 'done'}
                onClick={() => sendUIEvent({ type: 'home' })}
                onMouseOver={() => setHighlighted('done')}
                onMouseOut={() => setHighlighted('done')}
            />

            <ResultsButton
                text="Review"
                width={100}
                height={24}
                color="#83A2BB"
                fontSize="12px"
                x={w / 2 - 50}
                y={160}
                highlighted={highlighted === 'review'}
                onClick={() => setHidden(true)}
                onMouseOver={() => setHighlighted('review')}
                onMouseOut={() => setHighlighted('review')}
            />
        </svg>
    )
}

type ResultsButtonProps = {
    text: string
    width: number
    height: number
    color: string
    highlighted: boolean
    fontSize: string
} & SVGAttributes<SVGElement>

const ResultsButton: FunctionComponent<ResultsButtonProps> = ({
    color,
    width,
    height,
    text,
    fontSize,
    x,
    y,
    highlighted,
    onClick,
    onMouseOver,
    onMouseLeave,
}) => {
    return (
        <svg
            onClick={onClick}
            x={x}
            y={y}
            style={{ cursor: 'pointer' }}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
        >
            <rect width={width} height={height} rx="8" ry="8" fill={color} />
            <text
                x={width / 2}
                y={height / 2}
                {...textOptions}
                fontSize={fontSize}
                dominantBaseline="middle"
                fill="white"
                opacity={highlighted ? '80%' : '100%'}
            >
                {text}
            </text>
        </svg>
    )
}

export default Results
