import { CSSProperties, FunctionComponent, SVGAttributes } from 'react'
import UserIcon from '../../common/UserIcon'
import { FONT } from '../styling'

type NamePlateProps = {
    username?: string
} & SVGAttributes<SVGElement> &
    CSSProperties

const NamePlate: FunctionComponent<NamePlateProps> = ({ x, y, username, alignItems = 'flex-start' }) => {
    const textOptions: CSSProperties = {
        fontSize: '11px',
        fontFamily: FONT,
        fontStyle: 'italic',
        fontWeight: 'bold',
        margin: 0,
        color: '#7A9CB8',
    }

    return (
        <svg x={x} y={y} width={154} height={40}>
            <foreignObject x={0} y={0} width="100%" height="100%">
                <div style={{ display: 'flex', alignItems }}>
                    <UserIcon name={username || ''} size={40} />
                    <p
                        style={{
                            ...textOptions,
                            flexGrow: 1,
                            padding: '4px 0 4px 8px',
                        }}
                    >
                        {username}
                    </p>
                </div>
            </foreignObject>
        </svg>
    )
}

export default NamePlate
