import { Context, createContext } from 'react'
import useGameAudio from '../useGameAudio'
import { UIEventSender } from '../useGameEngine'

export type GameTools = {
    playSound: ReturnType<typeof useGameAudio>
    sendUIEvent: UIEventSender
}
const GameToolsContext: Context<GameTools> = createContext({
    playSound: (id) => console.log('playSound - ', id, 'using the default context functionality'),
    sendUIEvent: (event) => console.log('sendUIEvent - ', event, 'using the default context functionality'),
})

export default GameToolsContext
