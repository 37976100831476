import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { RootState } from './store'

export type PreferencesState = {
    sounds: boolean
}

const defaultValue: PreferencesState = { sounds: true }

const storedValue = window.localStorage.getItem('preferences')
const initial: PreferencesState = storedValue ? JSON.parse(storedValue) : defaultValue

const slice = createSlice({
    name: 'preferences',
    initialState: initial,
    reducers: {
        toggleSounds(state) {
            state.sounds = !state.sounds
        },
    },
})

export function usePreferencesSelector<T>(f: (state: PreferencesState) => T): T {
    return useSelector((state: RootState) => f(state.preferences))
}

export default slice.reducer

export const preferencesActions = slice.actions
