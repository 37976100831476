import { FunctionComponent, useContext, useState } from 'react'
import { useGameSelector } from '../../state/game'
import styles from '../Game.module.css'
import { FONT } from '../styling'
import GameToolsContext from './GameTools'

type AlertProps = {
    cx?: number
    cy?: number
}

export const Alert: FunctionComponent<AlertProps> = ({ cx = 510, cy = 372 }) => {
    const alert = useGameSelector((s) => s.alert)
    const [highlighted, setHighlighted] = useState<'done' | undefined>()
    const { sendUIEvent } = useContext(GameToolsContext)

    if (!alert) return <></>

    const { title, subtitle, action } = alert

    const w = 350
    const h = 200
    const stroke = 6
    const textOptions = {
        textAnchor: 'middle',
        dominantBaseline: 'hanging',
        fontFamily: FONT,
        fontWeight: 'bold',
    }

    const bw = 160
    const bh = 32

    return (
        <svg className={styles['shadow']} x={cx - w / 2} y={cy - h / 2} width={w} height={h}>
            <rect x={0} y={0} width={w} height={h} fill="#e8e8e8" rx="8" ry="8" />
            <rect
                x={stroke}
                y={stroke}
                width={w - stroke * 2}
                height={h - stroke * 2}
                fill="url(#panel-background)"
                rx="8"
                ry="8"
            />
            <text x={w / 2} y={24} fill="white" fontStyle="italic" fontSize="32px" {...textOptions}>
                {title}
            </text>
            <text x={w / 2} y={75} fill="white" opacity="60%" fontSize="20px" {...textOptions}>
                {subtitle}
            </text>

            <svg
                onClick={() => sendUIEvent(action)}
                x={w / 2 - bw / 2}
                y={120}
                style={{ cursor: 'pointer' }}
                onMouseOver={() => setHighlighted('done')}
                onMouseLeave={() => setHighlighted(undefined)}
            >
                <rect width={bw} height={bh} rx="8" ry="8" fill="#83A2BB" />
                <text
                    x={bw / 2}
                    y={bh / 2}
                    {...textOptions}
                    dominantBaseline="middle"
                    fill="white"
                    opacity={highlighted === 'done' ? '80%' : '100%'}
                >
                    Go
                </text>
            </svg>
        </svg>
    )
}
