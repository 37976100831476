import { FunctionComponent } from 'react'
import { Redirect, Route, Switch, useParams } from 'react-router-dom'
import About from './about/About'
import { computer, pvp } from './apis/gtoons'
import './App.css'
import DeckBuilder from './deck-builder/DeckBuilder'
import DeckBuilderEdit from './deck-builder/DeckBuilderEdit'
import DebugGame from './game/DebugGame'
import Game from './game/Game'
import Home from './home/Home'
import Lobby from './lobby/scenes/Lobby'
import Setup from './setup/Setup'
import { useUserSelector, validUser } from './state/user'

const version = process.env.REACT_APP_VERSION

function App() {
    const isUserValid = useUserSelector(validUser)

    return (
        <div className="App">
            <Routes needsSetup={!isUserValid} />
            <footer>{version}</footer>
        </div>
    )
}

type RouteProps = {
    needsSetup?: boolean
}

const Routes: FunctionComponent<RouteProps> = ({ needsSetup }) => {
    if (needsSetup) {
        return (
            <Switch>
                <Route exact path="/setup" component={Setup} />
                <Route path="*">
                    <Redirect to="/setup" />
                </Route>
            </Switch>
        )
    }

    return (
        <Switch>
            <Route exact path="/home" component={Home} />
            <Route exact path="/about" component={About} />
            <Route exact path="/deckbuilder" component={DeckBuilder} />
            <Route exact path="/deckbuilder/:id" component={DeckBuilderEdit} />
            <Route exact path="/lobby" component={Lobby} />
            <Route exact path="/play/computer" component={PlayComputer} />
            <Route exact path="/play/:id" component={PlayPVP} />
            <Route exact path="/setup" component={Setup} />
            {version === 'dev' && <Route exact path="/debug/game" component={DebugGame} />}
            <Route path="*">
                <Redirect to="/home" />
            </Route>
        </Switch>
    )
}

const PlayComputer: FunctionComponent = () => <Game type={computer} />
const PlayPVP: FunctionComponent = () => {
    const { id } = useParams<{ id: string }>()
    return <Game type={pvp(id)} />
}

export default App
